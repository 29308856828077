import React, {  useState } from "react";
import { Fragment } from "react";
 import './videogallery.css';
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Nav } from "../../Nav bar/sidenavbar/nav";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import { NavBar } from "../../Nav bar/Navbar";

import {  Footer } from "../../Footer/Footer";

import Loader from "../../loader";

import { FaExclamationCircle } from 'react-icons/fa';
import useGalleryData from "./galleryapi";
import {
       WhatsappShareButton,
       TwitterShareButton,
       
       FacebookShareButton,
      
} from 'react-share';
export const Videocard = (props) => {

       const [isShowMore, setIsShowMore] = useState(false);

       const toggleReadMoreLess = () => {
              setIsShowMore(!isShowMore);
       };
       const shareUrl = window.location.href; // Change this to the appropriate URL
       const title = props.title;
       const imageUrl = props.src;

       return (
              <Fragment>


                     <Card id="gcard" >
                            <iframe id="gImage" src={props.src} title="YouTube video player" frameBorder="0" allow="accelerometer; 
         clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
  <Card.Body id="gcard_body">
                                   {/* <Card.Text id="">

                                          {props.date}
                                   </Card.Text> */}
                                   <Card.Title id="datas" >

                                          {props.title}
                                          {isShowMore && (
                                                 <div className="description">
                                                      
                                                        
                                          
                                                               <span>
                                                                      {props.date},
                                                               </span>
                                                        <p>
                                                               {props.caption}
                                                        </p>
                                                 </div>
                                                

                                          )}
                                          <button className="read_more_less" onClick={toggleReadMoreLess}>
                                                 {isShowMore ? "show less" : "..more"}
                                          </button>


                                
                                   </Card.Title>




                                   <div id="card-share" className="d-flex justify-content-center">
                                          <WhatsappShareButton title={title} url={shareUrl} image={imageUrl}>
                                                 <a href='#'>{/* eslint-disable-line jsx-a11y/anchor-is-valid */}


                                                        <svg style={{ height: "25px", width: "25px" }} viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#e10600" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z" fill="#e10600"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z" fill="#e10600"></path> </g></svg>
                                                 </a>
                                          </WhatsappShareButton>


                                          <FacebookShareButton style={{ alignSelf: "auto" }} title={title} url={shareUrl} image={imageUrl}>
                                                 <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <svg style={{ height: "25px", width: "25px", marginLeft: "20px" }} fill="#e10600" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z"></path> <path d="M146.8,313.7c10.3,0,21.3,3.2,21.3,3.2l6.6-39.2c0,0-14-4.8-47.4-4.8c-20.5,0-32.4,7.8-41.1,19.3 c-8.2,10.9-8.5,28.4-8.5,39.7v25.7H51.2v38.3h26.5v133h49.6v-133h39.3l2.9-38.3h-42.2v-29.9C127.3,317.4,136.5,313.7,146.8,313.7z"></path> </g> </g></svg>

                                                 </div>
                                             
                                          </FacebookShareButton>
                                  
                                          <TwitterShareButton title={title} url={shareUrl} image={imageUrl} style={{ display: 'flex', alignItems: 'center' }}>

                                                 <a href='#'>{/* eslint-disable-line jsx-a11y/anchor-is-valid */}



                                                        <svg style={{ height: "25px", width: "25px" }} fill="#e10600" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z"></path> <path d="M234.3,313.1c-10.2,6-21.4,10.4-33.4,12.8c-9.6-10.2-23.3-16.6-38.4-16.6c-29,0-52.6,23.6-52.6,52.6c0,4.1,0.4,8.1,1.4,12 c-43.7-2.2-82.5-23.1-108.4-55c-4.5,7.8-7.1,16.8-7.1,26.5c0,18.2,9.3,34.3,23.4,43.8c-8.6-0.3-16.7-2.7-23.8-6.6v0.6 c0,25.5,18.1,46.8,42.2,51.6c-4.4,1.2-9.1,1.9-13.9,1.9c-3.4,0-6.7-0.3-9.9-0.9c6.7,20.9,26.1,36.1,49.1,36.5 c-18,14.1-40.7,22.5-65.3,22.5c-4.2,0-8.4-0.2-12.6-0.7c23.3,14.9,50.9,23.6,80.6,23.6c96.8,0,149.7-80.2,149.7-149.7 c0-2.3,0-4.6-0.1-6.8c10.3-7.5,19.2-16.7,26.2-27.3c-9.4,4.2-19.6,7-30.2,8.3C222.1,335.7,230.4,325.4,234.3,313.1z"></path> </g> </g></svg>
                                                 </a>
                                          </TwitterShareButton>


                                   </div>
                            </Card.Body>
                     </Card>


              </Fragment>
       )
}

export const VideoGallery=()=>{

       var settings = {
              dots: true,
              infinite: false,
              speed: 500,
              swipeToSlide: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 0,
              responsive: [
                     {
                            breakpoint: 1068,
                            settings: {
                                   slidesToShow: 3,
                                   slidesToScroll: 1,
                            }
                     },
                     {
                            breakpoint: 768,
                            settings: {
                                   slidesToShow: 2,
                                   slidesToScroll: 1,
                            }
                     },

                     {
                            breakpoint: 680,
                            settings: {
                                   slidesToShow: 1,
                                   slidesToScroll: 1,

                            }

                     },
                     {
                            breakpoint: 500,
                            settings: {
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                                   appendDots: dots => (
                                          <div
                                                 style={{

                                                        //   backgroundColor: "#ddd",
                                                        borderRadius: "10px",
                                                        padding: "10px"
                                                 }}
                                          >
                                                 <br />
                                                 <br />
                                                 <ul style={{ marginLeft: "-100px", margin: "0px" }}> {dots} </ul>
                                          </div>
                                   ),
                            }
                     },
                     {
                            breakpoint: 400,
                            settings: {
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                                   appendDots: dots => (
                                          <div
                                                 style={{

                                                        //   backgroundColor: "#ddd",
                                                        borderRadius: "10px",
                                                        padding: "10px"
                                                 }}
                                          >
                                                 <br />
                                                 <br />
                                                 <ul style={{ marginLeft: "-60px", margin: "0px" }}> {dots} </ul>
                                          </div>
                                   ),
                            }
                     },
                     {
                            breakpoint: 280,
                            settings: {
                                   slidesToShow: 1,
                                   slidesToScroll: 1
                            }
                     },
              ],

              appendDots: dots => (
                     <div
                            style={{

                                   //   backgroundColor: "#ddd",
                                   borderRadius: "10px",
                                   padding: "10px"
                            }}
                     >
                            <br />
                            <br />
                            <ul style={{ margin: "0px" }}> {dots} </ul>
                     </div>
              ),
              customPaging: i => (


                     <div
                            id="appendots"
                            style={{
                                   marginTop: "100px",
                                   width: "28px",
                                   marginLeft: "-200px"

                            }}
                     >

                            {i + 1}
                     </div>
              )
       };

              const { data: Edata, isLoading, isError } = useGalleryData();

              if (isLoading) {
                     return <Loader />;
              }

              if (isError) {
                     return (
                            <div id="error_container" className="text-center">
                                   <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                                   <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                                          Oops! Something went wrong.
                                   </h1>
                            </div>
                     );
              }
    return(
        <Fragment>
           

                  <NavBar />
             
                  <Leaders />

                  <div id="homepage_container" className="row">
                         <Nav />
                         <div className=" col-xs-1 col-sm-2   col-md-2 col-lg-2   col-xl-2  col-xxl-2">
                                <Sidebar />
                         </div>
                         <div id="main_component" className="col-sm-10 col-md-12 col-lg-10  col-xl-10  col-xxl-10">
                                <div id="statement_container">
                                       <h1 id="statement_text">
                                              Quick Bite
                                       </h1>
                                       <span className="d-flex">  <hr id="" /> <span className="dots1"> .... </span> </span>  </div>
                        
                                <div className='sliderparent'      >

                                       <Slider {...settings}>
                                              {Edata.filter((item) => item.type==="videos"  && item.isActive === true).map((item, index) => (
                                                     <div>
                                                            <Videocard
                                                                   // src="https://mts-random.s3.ap-south-1.amazonaws.com/suci/event/image1.JPG"
                                                                   // src="https://mts-random.s3.ap-south-1.amazonaws.com/suci/gallery/image1.JPG" 

                                                                   src={item.image}

                                                                   caption={item.desc} title={item.title}
                                                               //     date={format(new Date(item.date), 'MMMM d, yyyy ')}
                                                               //    date = {format(new Date(item.date), 'MMMM d, yyyy')}
                                                               date={item.date}

                                                               //     route='/404'
                                                            // text=" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."
                                                            />
                                                     </div>
                                            
                                            ))}
                                            

                                          

                                       </Slider>
                                </div>

                        
                         </div>  </div>

        
     <br/>

     <br/>    
            <Footer/>  
        </Fragment>
    )
}