import React, { Fragment, useState } from "react";
import { NavBar } from "../../Nav bar/Navbar";
import { Footer } from "../../Footer/Footer";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Nav } from "../../Nav bar/sidenavbar/nav";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";

import { FaExclamationCircle } from 'react-icons/fa';
export const Ganadabi = () => {
    const [iframeError, setIframeError] = useState(false);

    const handleIframeLoad = () => {
        // If the iframe loads successfully, set the error state to false
        setIframeError(false);
    };

    const handleIframeError = () => {
        // If there is an error loading the iframe, set the error state to true
        setIframeError(true);
    };

    // if (isLoading) {
    //     return <Loader />;
    // }

    if ( iframeError) {
        return (
            <Fragment>
                <div id="error_container" className="text-center">
                    <FaExclamationCircle
                        className="error-icon"
                        style={{ color: 'rgb(225,6,0)', fontSize: '20px' }}
                    />
                    <h1
                        id="error_text"
                        style={{
                            color: 'rgb(225,6,0)',
                            fontSize: '16px',
                            fontFamily: 'barlow condensed',
                        }}
                    >
                        Oops! Something went wrong.
                    </h1>
                </div>
                {/* You can add more specific error handling UI here */}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <NavBar />

             <Leaders />

                <div id="homepage_container" className="row">
                    {/* Nav component is created for the hamburger button and broadcast container above the sidebar menu    */}
                    <Nav />
                    <div className="col-xs-1 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-10">
                        <div className="container-fluid">
                            <div id="statement_container">
                                <h1 id="statement_text">Ganadabi(Bengali)</h1>
                                <span className="d-flex">
                                    <hr id="statementhr" />
                                    <span className="dots1"> .... </span>{" "}
                                </span>{" "}
                            </div>
                            {!iframeError && (
                                <iframe
                                    title="Ganadabi"
                                    src="https://ganadabi.com"
                                    style={{
                                        marginLeft: "10px",
                                        width: "99%",
                                        height: "500px",
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                    }}
                                    onLoad={handleIframeLoad}
                                    onError={handleIframeError}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};


