import React from "react";
import { Fragment } from "react";
import { Leaders } from "./Mainpagecomp/Leader/leaders";

import { NavBar } from "./Nav bar/Navbar";
import { Sidebar } from "./Nav bar/sidenavbar/sidebar";
import { Nav } from "./Nav bar/sidenavbar/nav";
import { Footer } from "./Footer/Footer";

export const Renovation=()=>{
    return(
        <Fragment>
          
        
                <div>
                <div className="container-fluid">
                    <NavBar />
{/* css for this component is written in app.css */}
                    <br /> <br /> <br />
                    <Leaders />
                  

                    <div id="homepage_container" className="row">
                        <Nav/>
                        <div className=" col-xs-1 col-sm-2   col-md-3 col-lg-2   col-xl-2  col-xxl-2">
                            <Sidebar />
                        </div>
                        <div id="main_component" className="col-sm-10  ">

                            <div className="renovation_container">
                                <div>
                                    <img className='suci' src='suci.jpg' alt="logo" />
                                </div>
                                <h2 className=' renovation d-flex justify-content-center'>This page is under renovation; we will be back soon.</h2>

                            </div>
                           
                        </div>
                    </div>
                    <Footer/>
                  </div>

              </div>
  

        </Fragment>
    )
}