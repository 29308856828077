
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";
import { Footer } from "../../Footer/Footer";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import "./proleterian.css"

import { Nav } from "../../Nav bar/sidenavbar/nav";

import { Link } from "react-router-dom";

import { useOrgansData } from "./proletarian";

export const SarbaharaDristikon = () => {
    const { data: rawData, isLoading } = useOrgansData();

    const [Edata, setEdata] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    useEffect(() => {
        if (isLoading || !rawData || rawData.length === 0) {
            return;
        }

        // Filter data based on the condition name = "sarbahara dristikon"
        const condition = 'sarbahara dristikon';
        const filteredData = rawData.filter((item) => item.name === condition);

        // Format date to display only the date part
        const formattedData = filteredData.map((item) => ({
            ...item,
            date: new Date(item.pub_date).toLocaleDateString('en-GB'),
        }));
        setEdata(formattedData);

        // Extract unique years from data and sort them in descending order
        const uniqueYears = Array.from(new Set(formattedData.map((item) => new Date(item.pub_date).getFullYear())));
        const sortedYears = uniqueYears.sort((a, b) => b - a);

        // Set the selected year to the maximum year
        const defaultSelectedYear = Math.max(...sortedYears);
        setSelectedYear(defaultSelectedYear);

        // Set state for years
        setYears(sortedYears);

        // Update Edata with the filtered and formatted data
    
    }, [isLoading, rawData]); // Dependency array to ensure useEffect runs when isLoading or rawData changes


    return (
        <Fragment>
            <div className="container-fluid">
                <NavBar />
                
                <Leaders />

                <div id="homepage_container" className="row">
                    {/* Nav component is created for the hamburger button and broadcast container above the sidebar menu */}
                    <Nav />
                    <div className="col-xs-1 col-sm-2 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                        <div id="statement_container">
                            <h1 id="statement_text">        Sarbahara Dristikon (Hindi)
                            </h1>
                            <span className="d-flex">  <hr id="statementhr" />
                                <span className="dots1"> .... </span> </span>   </div>
                        {/* <h1 className='Bookssectionmainhead1'>
                            Sarbahara Dristikon (Hindi)
                        </h1> */}
                        <br />
                        <div className="card">
                            <nav
                                className="d-flex justify-content-between align-items-center"
                                style={{
                                    background: 'white',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <h2 style={{ color: 'rgb(225, 6, 0)', fontWeight: 'bold', margin: '0' }}>{selectedYear}</h2>
                                <select
                                    style={{
                                        width: '200px',
                                        margin: '0',
                                        fontWeight: "bold",
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                >
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </nav>
                            <div id="months_container" className="card-body d-flex align-items-center">
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", gap: "20px" }}>
                                    {Edata
                                        .filter((item) => new Date(item.pub_date).getFullYear() === selectedYear)
                                        .map((item, index) => (
                                            <div key={index} className="responsive-card" style={{ borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px", width: "200px", height: "100%", position: "relative" }}>
                                               
                                                <Link to={item.doc}> <img
                                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px 8px 0 0" }}
                                                    src={item.thumbnail} // replace with the actual image source
                                                    alt={`Publication Thumbnail ${index}`}
                                                />
                                                </Link> 
                                               
                                                <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, padding: "5px", backgroundColor: "rgba(255, 255, 255, 0.8)", textAlign: "center" }}>
                                                    <h6 style={{ fontSize: "12px", fontWeight: "bold", margin: "0" }}>
                                                        {item.date}
                                                       
                                                    </h6>
                                                    <h5 style={{ fontSize: "16px", fontWeight: "bold", margin: "3px 0" }}>
                                                        Volume {item.volume} No. {item.no}
                                                    </h5>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {/* <div id="card-share" className="d-flex justify-content-center">
                            <Link to="/">


                                <svg fill="#e10600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 495.398 495.398" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"></path> <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"></path> </g> </g> </g> </g></svg>

                            </Link>



                        </div> */}
                          </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
};

