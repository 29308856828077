
import React, { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import './sidebar.css';

import Accordion from "react-bootstrap/Accordion";
/* eslint-disable */
// export const Sidebar= () => {
//     const navigate = useNavigate();



    


//     return (

// <Fragment >




// <div className="search_bar_container">
               
             

//                 <br />    <br />
//             {/* </div> */}
//                 <nav id="side-bar-sm" class="navbar navbar-expand-lg">
                   
//                     <div style={{ minHeight: "120px", }}  >
                       
//                         {/* -collapse-show */}

//                         <div class="collapse navbar-collapse collapse-horizontal" id="collapseWidthExample">
//                             <div class="" style={{ width: "200px" }}>
//                                 <article className="Archiveleft">




//                                     <div className="sidemenu_bar">
//                                         <div>
//                                             <Accordion  >
//                                                 <Accordion.Item className="accordion1" eventKey="0">
//                                                     <Accordion.Header onClick={() => navigate('/')} ><p id="heading">  Home
//                                                     </p>  </Accordion.Header>

//                                                     {/* <Accordion.Body className=" accordion_body "     >
//                                                     <Link id="link" to="/Statement">
//                                                         <li>
//                                                             <a href="#speakercon" className="dropdown-item">
//                                                                 Statement
//                                                             </a>
//                                                         </li>
//                                                     </Link>


//                                                     <Link id="link" to="/Event">
//                                                         <li>
//                                                             <a href="#" className="dropdown-item">
//                                                                 Mass struggle
//                                                             </a>
//                                                         </li>
//                                                     </Link>
//                                                     <Link id="link" to="/News">
//                                                         <li>
//                                                             <a href="#" className="dropdown-item">
//                                                                 News
//                                                             </a>
//                                                         </li>
//                                                     </Link>

//                                                 </Accordion.Body> */}

//                                                 </Accordion.Item>


//                                                 <Accordion.Item className="accordion1" eventKey="1">
//                                                     <Accordion.Header ><p id="heading">  Foundation
//                                                     </p>  </Accordion.Header>


//                                                     <Accordion.Body className=" accordion_body "     >
//                                                         <Link id="link" to="/History">
//                                                             <li>
//                                                                 <a href="#speakercon" className="dropdown-item">
//                                                                     Party History
//                                                                 </a>
//                                                             </li>
//                                                         </Link>


//                                                         <Link id="link" to="/Founder">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Homage to Shibdas Ghosh
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link id="link" to="/centralcommittee">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Central Committee
//                                                                 </a>
//                                                             </li>
//                                                         </Link>

//                                                     </Accordion.Body>
//                                                 </Accordion.Item>



//                                                 <Accordion.Item className="accordion1" eventKey="2">
//                                                     <Accordion.Header> <p id="heading">  Publication</p>
//                                                     </Accordion.Header>
//                                                     <Accordion.Body className=" accordion_body "    >
//                                                         <Link id="link" to="/sbook">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Com. Shibdas Ghosh
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>

//                                                         <Link id="link" to="/nbook">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Com. Nihar Mukherjee
//                                                                 </a>
//                                                             </li>
//                                                         </Link>

//                                                         <Link id="link" to="/pbook">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Com. Provas Ghosh
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link id="link" to="/404">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Others
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
//                                                     </Accordion.Body>
//                                                 </Accordion.Item>


//                                                 <Accordion.Item className="accordion1" eventKey="3">
//                                                     <Accordion.Header><p id="heading">Organs</p>
//                                                     </Accordion.Header>
//                                                     <Accordion.Body className=" accordion_body "    >

//                                                         <Link id="link" to="/Proletarian-era">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Proletarian Era (English)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>
//                                                         <Link id="link" to="/Sarbahara-Dristikon">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Sarbahara Dristikon (Hindi)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>
//                                                         <Link id="link" to="/Ganadabi">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Ganadabi (Bengali)
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
                                                       

//                                                         <Link id="link" to="/Sarbahara_Kranti">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Sarbahara  (Odia)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>
//                                                         <Link id="link" to="/Unity">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Unity (Malayalam)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>
                                                       


//                                                         <Link id="link" to="/Karmika_Dristhikona">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Karmika Drushtikona (Kannada)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>

//                                                         <Link id="link" to="/Socialst_Viplavam">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Socialist Viplavam (Telugu)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>

//                                                         <Link id="link" to="/Paattali_Chintani">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Pattali Chintani (Tamil)
//                                                                 </a>
//                                                             </li>{" "}
//                                                         </Link>
                                                       
//                                                     </Accordion.Body>
//                                                 </Accordion.Item>

//                                                 <Accordion.Item className="accordion1" eventKey="4">
//                                                     <Accordion.Header><p id="heading">  Organization</p>


//                                                     </Accordion.Header>
//                                                     <Accordion.Body className=" accordion_body "  >
                                                      
//                                                         <Link to="/AIUTUC" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     AIUTUC                        </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link to="/AIKKMS" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     AIKKMS

//                                                                 </a>
//                                                             </li>
//                                                         </Link>
                                                      
//                                                         <Link to="/AIDSO" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     AIDSO
//                                                                 </a>
//                                                             </li>
//                                                         </Link>


//                                                         <Link to="/AIDYO" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     AIDYO
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link to="/AIMSS" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     AIMSS
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link to="/KOMSOMOL" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     KOMSOMOL
//                                                                 </a>
//                                                             </li>
//                                                         </Link>
                                                      
                                                       
//                                                     </Accordion.Body>
//                                                 </Accordion.Item>

//                                                 <Accordion.Item className="accordion1" eventKey="5">
//                                                     <Accordion.Header><p id="heading">  Gallery</p>


//                                                     </Accordion.Header>
//                                                     <Accordion.Body className=" accordion_body "  >

//                                                         <Link to="/photo" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Photos                     </a>
//                                                             </li>
//                                                         </Link>
//                                                         <Link to="/video" id="link">
//                                                             <li>
//                                                                 <a href="#" className="dropdown-item">
//                                                                     Videos

//                                                                 </a>
//                                                             </li>
//                                                         </Link>



//                                                     </Accordion.Body>
//                                                 </Accordion.Item>

                                               
//                                                 <Accordion.Item className="accordion1" eventKey="6">
//                                                     <Accordion.Header onClick={() => navigate("/Contact_us")} ><p id="heading">Contact Us</p>
//                                                     </Accordion.Header>

//                                                 </Accordion.Item>


//                                             </Accordion>
//                                         </div>


//                                         {/* // */}
//                                         <br />
                                        

//                                     </div>



















//                                 </article>  </div>
//                         </div>
//                     </div>
//                 </nav>
          
          
           
//             </div> </Fragment>
        
    
//     );
// };



 export const Sidebar = () => {
    const navigate = useNavigate();
    const [showOffcanvas, setShowOffcanvas] = useState(false);

      const handleToggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    return (
        <Fragment>
            <div className="search_bar_container">
                <br />
                <br />
              

                <nav
                    className="navbar navbar-expand-lg d-none d-lg-block" // Hidden in md and smaller devices
                >

                    <div className="search_bar_container">



                        <br />    <br />
                        {/* </div> */}
                        <nav id="side-bar-sm" class="navbar navbar-expand-lg">

                            <div style={{ minHeight: "120px", }}  >

                                {/* -collapse-show */}

                                <div class="collapse navbar-collapse collapse-horizontal" id="collapseWidthExample">
                                    <div class="" style={{ width: "200px" }}>
                                        <article className="Archiveleft">




                                            <div className="sidemenu_bar">
                                                <div>
                                                    <Accordion  >
                                                        <Accordion.Item className="accordion1" eventKey="0">
                                                            <Accordion.Header onClick={() => navigate('/')} ><p id="heading">  Home
                                                            </p>  </Accordion.Header>

                                                            {/* <Accordion.Body className=" accordion_body "     >
                                                    <Link id="link" to="/Statement">
                                                        <li>
                                                            <a href="#speakercon" className="dropdown-item">
                                                                Statement
                                                            </a>
                                                        </li>
                                                    </Link>


                                                    <Link id="link" to="/Event">
                                                        <li>
                                                            <a href="#" className="dropdown-item">
                                                                Mass struggle
                                                            </a>
                                                        </li>
                                                    </Link>
                                                    <Link id="link" to="/News">
                                                        <li>
                                                            <a href="#" className="dropdown-item">
                                                                News
                                                            </a>
                                                        </li>
                                                    </Link>

                                                </Accordion.Body> */}

                                                        </Accordion.Item>


                                                        <Accordion.Item className="accordion1" eventKey="1">
                                                            <Accordion.Header ><p id="heading">  Foundation
                                                            </p>  </Accordion.Header>


                                                            <Accordion.Body className=" accordion_body "     >
                                                                <Link id="link" to="/History">
                                                                    <li>
                                                                        <a href="#speakercon" className="dropdown-item">
                                                                            Party History
                                                                        </a>
                                                                    </li>
                                                                </Link>


                                                                <Link id="link" to="/Founder">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Homage to Shibdas Ghosh
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <Link id="link" to="/centralcommittee">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Central Committee
                                                                        </a>
                                                                    </li>
                                                                </Link>

                                                            </Accordion.Body>
                                                        </Accordion.Item>



                                                        <Accordion.Item className="accordion1" eventKey="2">
                                                            <Accordion.Header> <p id="heading">  Publication</p>
                                                            </Accordion.Header>
                                                            <Accordion.Body className=" accordion_body "    >
                                                                <Link id="link" to="/sbook">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Com. Shibdas Ghosh
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>

                                                                <Link id="link" to="/nbook">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Com. Nihar Mukherjee
                                                                        </a>
                                                                    </li>
                                                                </Link>

                                                                <Link id="link" to="/pbook">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Com. Provas Ghosh
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <Link id="link" to="/404">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Others
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                            </Accordion.Body>
                                                        </Accordion.Item>


                                                        <Accordion.Item className="accordion1" eventKey="3">
                                                            <Accordion.Header><p id="heading">Organs</p>
                                                            </Accordion.Header>
                                                            <Accordion.Body className=" accordion_body "    >

                                                                <Link id="link" to="/Proletarian-era">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Proletarian Era (English)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>
                                                                <Link id="link" to="/Sarbahara-Dristikon">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Sarbahara Dristikon (Hindi)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>
                                                                <Link id="link" to="/Ganadabi">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Ganadabi (Bengali)
                                                                        </a>
                                                                    </li>
                                                                </Link>


                                                                <Link id="link" to="/Sarbahara_Kranti">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Sarbahara  (Odia)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>
                                                                <Link id="link" to="/Unity">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Unity (Malayalam)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>



                                                                <Link id="link" to="/Karmika_Dristhikona">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Karmika Drushtikona (Kannada)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>

                                                                <Link id="link" to="/Socialst_Viplavam">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Socialist Viplavam (Telugu)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>

                                                                <Link id="link" to="/Paattali_Chintani">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Pattali Chintani (Tamil)
                                                                        </a>
                                                                    </li>{" "}
                                                                </Link>

                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item className="accordion1" eventKey="4">
                                                            <Accordion.Header><p id="heading">  Organization</p>


                                                            </Accordion.Header>
                                                            <Accordion.Body className=" accordion_body "  >

                                                                <Link to="/AIUTUC" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            AIUTUC                        </a>
                                                                    </li>
                                                                </Link>
                                                                <Link to="/AIKKMS" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            AIKKMS

                                                                        </a>
                                                                    </li>
                                                                </Link>

                                                                <Link to="/AIDSO" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            AIDSO
                                                                        </a>
                                                                    </li>
                                                                </Link>


                                                                <Link to="/AIDYO" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            AIDYO
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <Link to="/AIMSS" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            AIMSS
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <Link to="/KOMSOMOL" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            KOMSOMOL
                                                                        </a>
                                                                    </li>
                                                                </Link>


                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item className="accordion1" eventKey="5">
                                                            <Accordion.Header><p id="heading">  Gallery</p>


                                                            </Accordion.Header>
                                                            <Accordion.Body className=" accordion_body "  >

                                                                <Link to="/photo" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Photos                     </a>
                                                                    </li>
                                                                </Link>
                                                                <Link to="/video" id="link">
                                                                    <li>
                                                                        <a href="#" className="dropdown-item">
                                                                            Videos

                                                                        </a>
                                                                    </li>
                                                                </Link>



                                                            </Accordion.Body>
                                                        </Accordion.Item>


                                                        <Accordion.Item className="accordion1" eventKey="6">
                                                            <Accordion.Header onClick={() => navigate("/Contact_us")} ><p id="heading">Contact Us</p>
                                                            </Accordion.Header>

                                                        </Accordion.Item>


                                                    </Accordion>
                                                </div>


                                                {/* // */}
                                                <br />


                                            </div>



















                                        </article>  </div>
                                </div>
                            </div>
                        </nav>



                    </div>
                </nav>

                {/* Offcanvas for small and medium devices */}
                <div
                    className={`offcanvas offcanvas-start${showOffcanvas ? ' show' : ''}`}
                    data-bs-scroll="true"
                    tabIndex="-1"
                    id="offcanvasWithBothOptions"
                    aria-labelledby="offcanvasWithBothOptionsLabel"
                >
                    <div className="offcanvas-header p-2 d-flex justify-content-end">
                        {/* <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                            Backdrop with scrolling
                        </h5> */}
                        <button
                            type="button"
                            className="btn-close    text-light"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            // style={{backgroundColor:"rgb(225,6,0)", color:"white"}}
                            onClick={handleToggleOffcanvas}
                        ></button>
                    </div>
                    <div   style={{width:"100%"}} className="offcanvas-body">

                        {/* <div className="search_bar_container"> */}



                            
                            {/* </div> */}
                            {/* <nav id="side-bar-sm" class="navbar navbar-expand-lg"> */}

                                {/* <div style={{ minHeight: "120px", }}  > */}

                                    {/* -collapse-show */}

                                    {/* <div class="collapse navbar-collapse collapse-horizontal" id="collapseWidthExample"> */}
                                        {/* <div class="" style={{ width: "200px" }}>
                                            <article className="Archiveleft"> */}




                                                <div style={{width:"100%"}} className="d-flex justify-content-center">
                                    <div style={{ width: "100%" }} >
                                                        <Accordion style={{width:"30rem"}}  >
                                                            <Accordion.Item className="accordion1" eventKey="0">
                                                                <Accordion.Header onClick={() => navigate('/')} ><p id="heading">  Home
                                                                </p>  </Accordion.Header>

                                                                {/* <Accordion.Body className=" accordion_body "     >
                                                    <Link id="link" to="/Statement">
                                                        <li>
                                                            <a href="#speakercon" className="dropdown-item">
                                                                Statement
                                                            </a>
                                                        </li>
                                                    </Link>


                                                    <Link id="link" to="/Event">
                                                        <li>
                                                            <a href="#" className="dropdown-item">
                                                                Mass struggle
                                                            </a>
                                                        </li>
                                                    </Link>
                                                    <Link id="link" to="/News">
                                                        <li>
                                                            <a href="#" className="dropdown-item">
                                                                News
                                                            </a>
                                                        </li>
                                                    </Link>

                                                </Accordion.Body> */}

                                                            </Accordion.Item>


                                                            <Accordion.Item className="accordion1" eventKey="1">
                                                                <Accordion.Header ><p id="heading">  Foundation
                                                                </p>  </Accordion.Header>


                                                                <Accordion.Body className=" accordion_body "     >
                                                                    <Link id="link" to="/History">
                                                                        <li>
                                                                            <a href="#speakercon" className="dropdown-item">
                                                                                Party History
                                                                            </a>
                                                                        </li>
                                                                    </Link>


                                                                    <Link id="link" to="/Founder">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Homage to Shibdas Ghosh
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link id="link" to="/centralcommittee">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Central Committee
                                                                            </a>
                                                                        </li>
                                                                    </Link>

                                                                </Accordion.Body>
                                                            </Accordion.Item>



                                                            <Accordion.Item className="accordion1" eventKey="2">
                                                                <Accordion.Header> <p id="heading">  Publication</p>
                                                                </Accordion.Header>
                                                                <Accordion.Body className=" accordion_body "    >
                                                                    <Link id="link" to="/sbook">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Com. Shibdas Ghosh
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>

                                                                    <Link id="link" to="/nbook">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Com. Nihar Mukherjee
                                                                            </a>
                                                                        </li>
                                                                    </Link>

                                                                    <Link id="link" to="/pbook">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Com. Provas Ghosh
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link id="link" to="/404">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Others
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                </Accordion.Body>
                                                            </Accordion.Item>


                                                            <Accordion.Item className="accordion1" eventKey="3">
                                                                <Accordion.Header><p id="heading">Organs</p>
                                                                </Accordion.Header>
                                                                <Accordion.Body className=" accordion_body "    >

                                                                    <Link id="link" to="/Proletarian-era">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Proletarian Era (English)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>
                                                                    <Link id="link" to="/Sarbahara-Dristikon">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Sarbahara Dristikon (Hindi)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>
                                                                    <Link id="link" to="/Ganadabi">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Ganadabi (Bengali)
                                                                            </a>
                                                                        </li>
                                                                    </Link>


                                                                    <Link id="link" to="/Sarbahara_Kranti">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Sarbahara  (Odia)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>
                                                                    <Link id="link" to="/Unity">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Unity (Malayalam)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>



                                                                    <Link id="link" to="/Karmika_Dristhikona">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Karmika Drushtikona (Kannada)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>

                                                                    <Link id="link" to="/Socialst_Viplavam">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Socialist Viplavam (Telugu)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>

                                                                    <Link id="link" to="/Paattali_Chintani">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Pattali Chintani (Tamil)
                                                                            </a>
                                                                        </li>{" "}
                                                                    </Link>

                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                            <Accordion.Item className="accordion1" eventKey="4">
                                                                <Accordion.Header><p id="heading">  Organization</p>


                                                                </Accordion.Header>
                                                                <Accordion.Body className=" accordion_body "  >

                                                                    <Link to="/AIUTUC" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                AIUTUC                        </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to="/AIKKMS" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                AIKKMS

                                                                            </a>
                                                                        </li>
                                                                    </Link>

                                                                    <Link to="/AIDSO" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                AIDSO
                                                                            </a>
                                                                        </li>
                                                                    </Link>


                                                                    <Link to="/AIDYO" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                AIDYO
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to="/AIMSS" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                AIMSS
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to="/KOMSOMOL" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                KOMSOMOL
                                                                            </a>
                                                                        </li>
                                                                    </Link>


                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                            <Accordion.Item className="accordion1" eventKey="5">
                                                                <Accordion.Header><p id="heading">  Gallery</p>


                                                                </Accordion.Header>
                                                                <Accordion.Body className=" accordion_body "  >

                                                                    <Link to="/photo" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Photos                     </a>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to="/video" id="link">
                                                                        <li>
                                                                            <a href="#" className="dropdown-item">
                                                                                Videos

                                                                            </a>
                                                                        </li>
                                                                    </Link>



                                                                </Accordion.Body>
                                                            </Accordion.Item>


                                                            <Accordion.Item className="accordion1" eventKey="6">
                                                                <Accordion.Header onClick={() => navigate("/Contact_us")} ><p id="heading">Contact Us</p>
                                                                </Accordion.Header>

                                                            </Accordion.Item>


                                                        </Accordion>
                                                    </div>


                                                    {/* // */}
                                                    <br />


                                                </div>



















                                            {/* </article>  </div> */}
                                    {/* </div> */}
                                </div>
                            {/* </nav> */}



                        {/* </div>   </div> */}
                </div>
            </div>
        </Fragment>
    );
};

