// hero.js

import React, {  useRef, useState } from "react";
import './hero.css';
import Slider from "react-slick";

import getAwsSignature from "../../aws";
import { format } from 'date-fns';
import { FaExclamationCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';

/**
 * Function to fetch news canvas data from the API.
 * @returns {Promise<Array>} - Array of news canvas data.
 */
export const fetchNewsCanvasData = async () => {
  const apiUrl = process.env.REACT_APP_API_URL + '/news_canvas';
  const method = 'GET';
  const headers = await getAwsSignature(apiUrl, method);

  const response = await fetch(apiUrl, {
    method,
    headers,
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.map((item) => ({
    ...item,
    date: new Date(item.date).toLocaleDateString('en-US'),
  }));
};

/**
 * HeroSection component that displays a slider of news canvas items.
 * @returns {JSX.Element} - HeroSection JSX markup.
 */
export const Herosection = () => {
  const queryClient = useQueryClient();
  const prefetchedData = queryClient.getQueryData('newsCanvas');

  // Fetch news canvas data using react-query's useQuery hook
  const { data: Edata, error, isLoading } = useQuery('newsCanvas', fetchNewsCanvasData, {
    initialData: prefetchedData,
    onSuccess: (data) => {
      queryClient.invalidateQueries('otherQueryKey');
    },
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const slider = useRef(null);

  // Sort and slice data to display only the top 10 items
  const sortedData = Edata ? [...Edata].sort((a, b) => a.sequence - b.sequence).slice(0, 10) : [];

  // Function to render individual slider items
  const renderSliderItem = (item) => (
    <div key={item._id} className="slidecontainer">
      <div
        id="slide5"
        className="background-image-container"
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      >
        <div className="place_details">
          <h1 className="imagetitle">{item.title}</h1>
          <h5>
            {item.venue}, {'  '}
            {format(new Date(item.date), 'MMMM d, yyyy ')}
          </h5>
        </div>
      </div>
    </div>
  );

  // Slider settings
  const settings = {
    className: 'dotsbuttons',
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // Update activeIndex on slide change
    beforeChange: (current, next) => setActiveIndex(next),
    // Dots navigation
    appendDots: (dots) => (
      <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1, justifyContent: 'start' }}>
        <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0, justifyContent: 'start' }}>
          {dots.map((dot, index) => (
            <li
              key={index}
              onClick={() => {
                setActiveIndex(index);
                // Updated: Call slickGoTo on the slider ref using optional chaining
                slider.current?.slickGoTo(index);
              }}
              style={{
                width: '10px',
                height: '10px',
                margin: '0 4px',
                borderRadius: '50%',
                backgroundColor: index === activeIndex ? 'red' : 'white',
                cursor: 'pointer',
              }}
            ></li>
          ))}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1068,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {error ? (
        <div id="error_container" className="text-center">
          <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
          <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
            {'Oops! Something went wrong'}
          </h1>
        </div>
      ) : (
        <div id="herocontainer1" className="slider-border1 container">
          <Slider ref={slider} className="slider-border" {...settings}>
            {sortedData.map(renderSliderItem)}
          </Slider>
        </div>
      )}
    </div>
  );
};











