// App.js

import React from "react";
import './Mediaqueries/mediaqueries.css';
import "@fontsource/barlow-condensed";
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main } from './Pages/main';
import { Renovation } from './site_under_construction';
import { Karnataka } from './Mainpagecomp/media/detail';
import { Proletarian } from './Pages/organs/proletarian';
import { Ganadabi } from './Pages/organs/Ganadabi';
import { Karmika } from './Pages/organs/Karmika';
import { Paattali } from './Pages/organs/paattali';
import { Sarbahara } from './Pages/organs/sarbaharaKranti';
import { Socialist } from './Pages/organs/socialistVil';
import { Unity } from './Pages/organs/unity';
import { SarbaharaDristikon } from './Pages/organs/sarbahara_dristikon';
import { Read } from './Mainpagecomp/Event section/read';
import { PhotoGallery } from './Pages/Gallerymenu/photogallery';
import { VideoGallery } from './Pages/Gallerymenu/videogallery';
import { History } from './Pages/foundationmenu/party_history/History';
import { Founder } from './Pages/foundationmenu/homage/Founder';
import { Authorities } from './Pages/foundationmenu/Authorisation/authorities';
import { Shibidas } from './Pages/publicationmenu/shibidasGosh';
import { ProvasGosh } from './Pages/publicationmenu/provasGosh';
import { Nihar } from './Pages/publicationmenu/nihar';
import { AIDSO } from './Pages/organisation/aidso';
import { AIKKMS } from './Pages/organisation/aikims';
import { AIMSS } from './Pages/organisation/aimss';
import { AIUTUC } from './Pages/organisation/aiutuc';
import { KOMSOMOL } from './Pages/organisation/komsomol';
import { AIDYO } from './Pages/organisation/aidyo';
import { Contact } from './Pages/Contactmenu/contact';

import { PrintMedias } from './Mainpagecomp/statement/printmedia';
import Underconstruction from "./underconstruction/Underconstruction";

// Note that the development build is not optimized.
// To create a production build, use npm run build.

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Homepage */}
          <Route path="/" element={<Underconstruction />} />

          {/* Site under construction */}
          <Route path="/404" element={<Renovation />} />

          {/* Media details */}
          <Route path="/media/:id" element={<Karnataka />} />

          {/* Organs */}
          <Route path="/Proletarian-era" element={<Proletarian />} />
          <Route path="/Ganadabi" element={<Ganadabi />} />
          <Route path="/Karmika_Dristhikona" element={<Karmika />} />
          <Route path="/Paattali_Chintani" element={<Paattali />} />
          <Route path="/Sarbahara_Kranti" element={<Sarbahara />} />
          <Route path="/Socialst_Viplavam" element={<Socialist />} />
          <Route path="/Unity" element={<Unity />} />
          <Route path="/Sarbahara-Dristikon" element={<SarbaharaDristikon />} />

          {/* Read more */}
          <Route path="/readmore/:id" element={<Read />} />

          {/* Photo Gallery */}
          <Route path="/Photo" element={<PhotoGallery />} />

          {/* Video Gallery */}
          <Route path="/video" element={<VideoGallery />} />

          {/* Foundation menu */}
          <Route path="/History" element={<History />} />
          <Route path="/Founder" element={<Founder />} />
          <Route path="/centralcommittee" element={<Authorities />} />

          {/* Publication menu */}
          <Route path="/sbook" element={<Shibidas />} />
          <Route path="/pbook" element={<ProvasGosh />} />
          <Route path="/nbook" element={<Nihar />} />

          {/* Event details */}
          {/* <Route path="/Event_Details" element={<Details />} /> */}

          {/* Organization */}
          <Route path="/AIDSO" element={<AIDSO />} />
          <Route path="/AIKKMS" element={<AIKKMS />} />
          <Route path="/AIMSS" element={<AIMSS />} />
          <Route path="/AIUTUC" element={<AIUTUC />} />
          <Route path="/AIDYO" element={<AIDYO />} />
          <Route path="/KOMSOMOL" element={<KOMSOMOL />} />

          {/* Contact us */}
          <Route path="/Contact_us" element={<Contact />} />

          {/* Statement details */}
          <Route path="/statement/:id" element={<PrintMedias />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
