import { useQuery, useQueryClient } from 'react-query';
import getAwsSignature from '../../aws';

 export const fetchGalleryData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/gallery';

    const method = 'GET';
    const headers = await getAwsSignature(apiUrl, method);

    const response = await fetch(apiUrl, {
        method,
        headers,
    });

    const data = await response.json();

    return data.map((item) => ({
        ...item,
        date: new Date(item.date).toLocaleDateString('en-US'),
    }));
};

const useGalleryData = () => {
    const queryClient = useQueryClient();

    // Prefetch the data in the background
    // queryClient.prefetchQuery('galleryData', fetchGalleryData);
    const prefetchData = queryClient.getQueryData('galleryData');
    // Use useQuery to access the prefetched data
    const { data: galleryData, isLoading, isError } = useQuery('galleryData', fetchGalleryData, {
        initialData: prefetchData // Access prefetched data
    });

    return { data: galleryData, isLoading, isError };
};

export default useGalleryData;
