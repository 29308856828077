// ItemContext.js
import { createContext, useContext, useState } from 'react';

const ItemContext = createContext();

export const ItemProvider = ({ children }) => {
    const [itemData, setItemData] = useState(null);

    return (
        <ItemContext.Provider value={{ itemData, setItemData }}>
            {children}
        </ItemContext.Provider>
    );
};

export const useItemContext = () => useContext(ItemContext);