import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Slider from "react-slick";

// import useApiData from "../../apifetch";
import { FaExclamationCircle } from "react-icons/fa"; // Import error icon
import { format } from "date-fns";

import { Link } from "react-router-dom";

export const Ecard = (props) => {
    return (
        <Card id="ecard" style={{ width: '14.2rem', padding: '5px', height: '350px', overflow: 'hidden' }}>
            <Card.Img alt={props.alt} variant="top" id="EImage" src={props.src} />
            <Card.Body id="Cardsdescription" style={{   }}  >
                <Card.Text style={{ marginBottom: '8px',marginTop:"5px" ,fontSize:"1rem"}}>{props.date}</Card.Text>
                <Card.Title style={{
                    marginBottom: '2px', fontWeight: "600",
                    fontFamily: 'Barlow Condensed',  }}>{props.title}</Card.Title>
                    {/* <h5>
                        {props.vol}
                    </h5> */}
            </Card.Body>
            <div style={{ marginTop: '-20px', padding: '5px',  }} className="d-flex justify-content-between">
                {/* <a href={props.route}> */}
                <Link to={props.route}>

              
                    <Button className="download_button btn btn-sm" variant="danger">Download</Button>
                </Link>
            </div>
        </Card>

    );
};




export const E_SELECTEDWORK = ({ Edata, isLoading, isError }) => {
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1068,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

   
    
   
    const [selectedVolume, setSelectedVolume] = useState('Volume1'); // Default volume

    useEffect(() => {
        // You can perform additional logic here if needed
    }, []);

    const handleVolumeChange = (volume) => {
        setSelectedVolume(volume);
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div id="statement_container">
                    <h1 id="statement_text">Selected Work (English)</h1>
                    <span className="d-flex">
                        {' '}
                        <hr id="" /> <span className="dots1"> .... </span>{' '}
                    </span>
                </div>
                <br />  <br /> 
                <div className="volume-buttons">
                    {[1, 2, 3, 4].map((volume) => (
                        <button
                            key={volume}
                            className={selectedVolume === `Volume${volume}` ? 'btn btn-sm active' : 'btn btn-sm'}
                            onClick={() => handleVolumeChange(`Volume${volume}`)}
                        >
                            Volume {volume}
                        </button>
                    ))}
                </div>
                <div className="sliderparent">
                    {isLoading && <p>Loading...</p>}
                    {isError &&     
                    <div id="error_container" className="text-center">
                        <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                        <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                            Oops! Something went wrong.
                        </h1>
                    </div>
    }
                    {Edata && (
                        <Slider {...sliderSettings}>
                            {Edata
                                .filter((item) => item.writer === 'Shibdas Ghosh' && item.tag === 'Selected Work' && item.volGroup === selectedVolume )
                                .map((item, index) => (
                                    <div id="cards" key={index}>
                                        <Ecard src={item.image} alt={item.title} title={item.title} date={format(new Date(item.date), 'MMM d, yyy')} route={item.doc} />
                                    </div>
                                ))}
                        </Slider>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

