import React from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";
import { Org } from "./aidso";
import './aikims.css';
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Nav } from "../../Nav bar/sidenavbar/nav";

export const  AIKKMS=()=>{
    return(
        <Fragment>
            
            <div className="container-fluid">
                <NavBar />

              
                <Leaders />


                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">
               <div>
                            {/* importing Org container and using props method  */}
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/demand.jpg'
                                
                                OrgDetails="  In a statement dated 4 March, 2022, All India Kisan Khet Mazdoor Sangathan (AIKKMS) demanded of the Haryana Government to resolve the problems of 52,000 Anganwadi workers of the state with immediate effect. AIKKMS central leadership expressed surprise and anguish that though the demands for giving Anganwadi workers status of skilled and semi-skilled worker and giving them Dearness Allowance every six months have already been accepted, the BJP-led Haryana government has not yet implemented the demands.
    "
                                OrgDetails1="The Prime Minister himself announced way back on 12 September 2018 a monthly increase in salary by Rs 1,500 for the workers and Rs 750 for the helpers. These have also not been given effect to till date. On the contrary, false cases have been registered against Anganwadi workers and helpers, who have been staging sit-in dharnas in open parks amidst chilling colds for the last 3 months. Hundreds of them are being terminated or indiscriminately served show-cause notices. The locks of Anganwadi centers are being broken, even the family members are being threatened by bloc and district officers going their house to house, no remuneration has been paid since last 3 months. To thwart their March to Haryana State Assembly Chandigarh yesterday on 3 March, their buses and vehicles were either confiscated in several districts or stopped at toll plazas to prevent them from reaching the assembly premises. The leaders who manged to reach Chandigarh were taken into custody. AIKKMS strongly opposed all these oppressive measures and once again demanded that talks be resumed with Anganwadi Coordination Committee immediately to accept the justified demands.
" OrgDetails2="AIKKMS called upon all its units in the country and especially in the state of Haryana to come forward for all help to support the struggling Anganwadi workers against the oppression and harassment by the Haryana government.
It also called for joint efforts with Samyukta Kisan Morcha to press for the demands. On the occasion of International Women’s Day on 8 March, AIKKMS decided to join the dharnas of Anganwadi workers and launch a vigorous mass campaign in their favour across the country.

"
                                date="Published: March 21, 20224:12 pm"

                                OrgName="AKIMMS"
                                Title="AIKKMS demands immediate remedy to anganwadi workers, problems in Haryana"
                            />
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/All_India.jpg'
                                OrgDetails="  We shall build up mighty peasant and agricultural workers’ movement to overthrow ruthless exploitative capitalism 
                                All India Kishan and Khetmazdoor Sangathan (AIKKMS) organized it’s 3rd All India conference in Kolkata and Joynagar, West Bengal, from March 11 to 14 2023 . On 11 March 2023, an international seminar was held at Sarat Sadan, Howrah, where delegates from Nepal and Bangladesh took part. Comrades A H Dulal and A. Vishwakarma were the leaders of Bangladesh and Nepal delegation teams respectively. The delegate of USA could not attend due to last minute cancellation of VISA. The seminar condemned this heinous undemocratic act of the Central BJP Government. Written speech of USA delegate Comrade Joe Morman was read out in the seminar. Comrades R. Venkaiah from AIKS(A B) and Prem Singh Ghelaut from AIKMS, both SKM leaders, as well as Sankar Ghosh, General Secretary, AIKKMS, placed their views in the seminar. The speakers discussed at length the various aspects of the attacks of multinationals on peasants and agricultural workers. All the speakers unanimously held that an united struggle ought to be organized and strengthened against the assault of multinationals on agriculture round the globe.
 "date="Published: April 6, 202312:09 pm"
                                OrgDetails1="Next day delegate session started at Joynagar. 752 delegates and observers from 22 states participated. Comrade Satyawan, President, AIKKMS, hoisted the red flag and garlanded the martyrs column to mark augury of the conference. Then a six member presidium with Comrade Satyawan at the helm conducted the entire proceedings. A good number of delegates from different states participated in the deliberations, discussed on various aspects of political -organizational report, on the constitution, on the charter of demands. They unhesitatingly suggested various amendments which fine-tuned the documents. The deliberations reflected their true spirit and commitment towards escalating peasant movement along right track.
On 14 March 2023, in the 5th session of the conference, future action plan was placed. In that plan, it was elaborated how and in what way, the organization would develop in the coming days to fulfill the behest of Comrade Shibdas Ghosh, founder and guide of AIKKMS. Conference elected a new 149-member strong All India Committee. Comrades Satyawan and Sankar Ghosh were re-elected as President and General Secretary, respectively.   "
                                OrgDetails2="In the concluding session, Comrade Provash Ghosh, General Secretary, SUCI(C) and guest speaker of the conference delivered an inspiring speech touching upon all cardinal aspects of present national and international situations and the task of a class-conscious peasants’ organization. He advised to organize peasant movement based on higher culture, ethics and morality, as taught by Comrade Shibdas Ghosh, the great leader of the proletariat. He emphatically stated that the task of AIKKMS should be to develop class consciousness among the peasants and agricultural workers without which no revolutionary movement can develop. He advised every delegate to develop a habit of remaining with the masses and learning from them as taught by all the great Marxist thinkers—Marx, Engels, Lenin, Stalin, Mao Zedong and Shibdas Ghosh.
The conference concluded with this spirit: ‘‘We shall fight, we shall win.’’
"


                                Title="3rd All India Conference of AIKKMS"       />
                           
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}