import React from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";
import { Org } from "./aidso";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import { Nav } from "../../Nav bar/sidenavbar/nav";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";
export const  AIMSS=()=>{
    return(
        <Fragment>
            <div className="container-fluid">
                <NavBar />

        
                <Leaders />


                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">
             <div>
                            {/* importing Org container and using props method  */}
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/AIDSO_AIMSS.jpg'
                                OrgDetails="On 5 January last, a 50-year old lady was gang-raped and killed by miscreants at Nurbari Tea estate in Tezpur, Assam. As soon as this news of this brutality was received, AIDSO and AIMSS activists rushed to the spot and on the next day i.e. 6 January, a protest rally of 200 aggrieved and enraged local citizens was organized. From the rally, slogan was raised to arrest the culprits and give them exemplary punishment. Near Mahabhairav Police Station, a huge police force obstructed the rally. At this, the participants of the rally burst into anger and raised voice in support of their just demand. Sensing the public mood, the police promised to hunt down the culprits within two days and take them into custody. This timely intervention of AIDSO and AIMSS in upholding a righteous cause received huge appreciation and support among the masses there.

   "

                                date="Published: February 23, 20221:55 pm"
                                OrgName="AIMSS"
                                Title="AIDSO and AIMSS -Tezpur, Assam, against incident of gang-rape and murder"
                            />
                           

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}