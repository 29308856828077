import React from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";
import { Org } from "./aidso";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import { Nav } from "../../Nav bar/sidenavbar/nav";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";
export const AIDYO = () => {
    return (
        <Fragment>
          
            <div className="container-fluid">
                <NavBar />

                <Leaders />


                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">
             <div>
                            {/* importing Org container and using props method  */}
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/Subash.jpg'
                                OrgDetails="   “Those who say that the interests of the Hindus and Muslims are not the same actually do not speak the truth. Hunger, unemployment, decay of national industries, increase of death rates, illiteracy…these are basic questions and on these questions, the interests of the Hindus and Muslims are one and the same. We can win independence only by joint efforts of both the Hindus and Muslims.”   "
                                OrgDetails1="“Communalism has raised its ugly head in all and nakedness…Even the oppressed, the poor and ignorant long for independence. We hear the voices of Hindu Raj in India owing to a majority of Hindu population. These are all useless thoughts. Do the communal organizations solve any of the problems confronted by the working class? Do any such organizations have any answer to unemployment and poverty?…The idea of Savarkar and Hindu Mahasabha of anti-Muslim propaganda in practice means full collaboration with British.” (Speech at Coomilla, 14 June 1938, SW Vol III)     "
                                OrgDetails2="“The Hindu Mahasabha has deployed sannyasis and sannyasins with tridents in their hands to beg for votes. At the very sight of tridents and saffron robes, Hindus bow their head in reverence. By taking advantage of religion and desecrating it, the Hindu Mahasabha has entered the arena of politics. It is the duty of all Hindus to condemn it….Banish these traitors from national life. Do not listen to them.” (Speech at a public meeting in Jhargram, West Bengal, published in the Ananda Bazar Patrika on 12 May 1840)
“Religion should be totally kept out of politics. Religion should be one’s personal matter. There should be full liberty for an individual to profess any religion he wants as a human being. But politics should not be guided by religion or any supernatural concepts. Politics should be guided by economic, political and scientific reasoning.” (Crossroads)
“You, the peasants, be united. Otherwise, your demands for rights would not find recognition. It is an irony that those who produce food for us, die because of dearth of food. In order to remedy the situation, the peasants need to develop a united front among themselves.” (Speech at the meeting organized by Kisan Sabha in 1937)
"
                                date="Published: February 6, 20211:19 pm"
                                OrgName="AIDYO"
                                Title="OBSERVE 125th BIRTH ANNIVERSARY YEAR OF NETAJI SUBHAS CHANDRA BOSE IN A BEFITTING MANNER
"
                            />

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}