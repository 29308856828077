import React, { Fragment } from 'react'
import "./underconstruction.css"
import { Leaders } from '../Mainpagecomp/Leader/leaders';
import { NavBar } from '../Nav bar/Navbar';
import underconsructionSuciLogo from "../assets/mavoixlogosuci.png"



const Underconstruction = () => {
  return (
    <Fragment>
      <div>
    <div >
    <NavBar/>
    <Leaders />
    {/* <div className='underconlogo'>
    <img src={underconsructionSuciLogo} alt='suci-logo'/>
    </div> */}
        <div className='underconlogoandh1'>
          <div className='h1divundercon'>
          <h1 className='underconh1'>We are working on our website , we will be back soon!</h1>
          </div>
        <img  className='underconlogo' src={underconsructionSuciLogo} alt='logo'/>
        </div>
    </div>
    </div>
    </Fragment>
  )
}

export default Underconstruction