import React, { useEffect } from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";

import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";

import Slider from "react-slick";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";

import Card from 'react-bootstrap/Card';
import './sihibi.css';

import { Nav } from "../../Nav bar/sidenavbar/nav";
import {E_SELECTEDWORK, Ecard } from "./selectedwork_e";
// import Articleshindi from "../archive/articleshindi/Articleshindi";
import {ARTICLES_B } from "./articles_b";
import {ARTICLES_H } from "./articles_h";
import { handleSharing } from "../../share";

import usePublicationData from "./publicationapi";
import { format } from "date-fns";


export const Books=(props)=>{
  return(
    <Fragment>


      
        <div className="d-flex">

     
          
        
           

    <Card id="bookcard" style={{ width: '15rem' }}  >
    <Card.Img variant="top" id="bookimg" src='book1.png'  />
     
      <Card.Body >

            
                <Card.Title id="booktitle">{props.book_title}</Card.Title>
                <Card.Text id="book_year">
                  {props.book_year}
                </Card.Text>

                <div id="download" className="d-flex justify-content-between   card-footer">

                  <button id="bookbutton" className="btn btn-sm">
                    <a className="text-light" href={props.src}>  Download</a>
                  </button>
                  <Card.Link id="booktexts" href="#">

                <button
                  className="border-0 bg-light p-0" // Adjusted styling to remove background and padding
                  id="shareButton"
                  onClick={handleSharing} // Assuming handleSharing is a function that triggers sharing
                >
                  <img className=" shareicon" alt="share" src="./Icon/share1.png" />
                </button>
        {/* <img  className=" shareicon"src="./Icon/share1.png"/> */}
       </Card.Link>
                </div>

           

      </Card.Body>
    </Card>
 



          
       

      </div>
      
   
    </Fragment>
  )
}
export const Shibidas= () => {
  const { data: Edata, isLoading, isError, refetch } = usePublicationData();

  useEffect(() => {
    // Prefetch the data
    const prefetchData = async () => {
      await refetch();
    };

    prefetchData();
  }, [refetch]);


  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    Row:2,
    responsive: [
      {
        breakpoint: 1068,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },

      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };






  return (
    <Fragment>
      <div className="container-fluid">
        <NavBar />

      
        <Leaders />
        {/* col-sm-* - 576px
          col-md-* - 768px
          col-lg-* - 992px
          col-xl-* - 1200px
          col-xxl */}

        <div id="homepage_container" className="row">
          <Nav />
          <div className=" col-xs-1 col-sm-2   col-md-2 col-lg-2   col-xl-2  col-xxl-2">
            <Sidebar />
          </div>
          <div id="main_component" className="col-sm-10 col-md-12 col-lg-10  col-xl-10  col-xxl-10">
            <div className="central_committee_whole_container">
              {/* <h1 className='Bookssectionmainhead1'>Com.Shibdas Ghosh Books</h1> */}
              {/* <div id="centralcommitte" className="container "> */}


              <E_SELECTEDWORK Edata={Edata} isLoading={isLoading} isError={isError} />
         
              <ARTICLES_B Edata={Edata} isLoading={isLoading} isError={isError} />
              <ARTICLES_H Edata={Edata} isLoading={isLoading} isError={isError} />

              <div className="container-fluid">
                {/* <div className="text-align-center">
                <h1  className="Bookssectionmainhead text-align-center">
                  Mass Struggle
                </h1>
              </div> */}

                <div id="statement_container">
                  <h1 id="statement_text">
                    Others
                  </h1>
                  <span className="d-flex">  <hr id="" /> <span className="dots1"> .... </span> </span>  </div>

                {/* <p id="p">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry
                </p> */}

                <br />

                {/* <div className='sliderparent'>
                  <Slider {...settings}>
                    <div id="cards"   >

                      <Ecard
                        // src="https://mts-random.s3.ap-south-1.amazonaws.com/suci/event/image1.JPG"
                        src="./public/1964.jpg"
                        title="Lanugage Problem  "
                        date="September, 2022"
                        route={language}
                      // text=" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."
                      />

                    </div>
                   
                  </Slider>
                </div> */}
                <div className="sliderparent">
                  {isLoading && <p>Loading...</p>}
                  {isError && <p>Error loading data</p>}
                  {Edata && (
                    <Slider {...settings}>
                      {Edata
                        .filter((item) => item.writer === 'Shibdas Ghosh' && item.category === 'Book' && item.tag === "N/A" && item.volGroup === "N/A")
                        .map((item, index) => (
                          <div id="cards" key={index}>
                            <Ecard src={item.image} alt={item.title} title={item.title} date={format(new Date(item.date), 'MMM d, yyy')} route={item.doc} />
                          </div>
                        ))}
                    </Slider>
                  )}
                </div>


              
              </div>






                {/* <div className="central_committe_memeber">

                  <div className="container-fluid slide_container_c">
                    <Slider {...settings}>


                      <div id="eachcard" >
                        <Books book_year="September, 2022"
                         book_title="On Lanugage Problem" src={language} />
                      </div>





                    </Slider>
                  </div>

                </div> */}

              </div>
            {/* <div id="card-share" className="d-flex justify-content-center">
              <Link to="/">


                <svg fill="#e10600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 495.398 495.398" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"></path> <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"></path> </g> </g> </g> </g></svg>

              </Link>



            </div>   */}
             </div>

          </div>
       
        {/* </div> */}
        <Footer />
      </div> 
    </Fragment>
  )
}



// <Books book_year="September, 2022" book_title="On Lanugage Problem" src={language} />
