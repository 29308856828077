import React from "react";
import { Fragment } from "react";
import { useQuery, useQueryClient } from 'react-query';
import getAwsSignature from "../../aws";
import { FaExclamationCircle } from 'react-icons/fa';
 export const fetchBroadcastData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/broadcast';
    const method = 'GET';

    const headers = await getAwsSignature(apiUrl, method);

    const response = await fetch(apiUrl, {
        method,
        headers,
    });

    const data = await response.json();

    return data.map(item => ({
        ...item,
        date: new Date(item.date).toLocaleDateString('en-US'),
    }));
};

 export const Nav = () => {
    const queryClient = useQueryClient();

    // Prefetch the data in the background
    const prefetchData = queryClient.getQueryData('broadcast');
    queryClient.prefetchQuery('broadcast', fetchBroadcastData);

    const { data: broadcastData, isLoading, isError } = useQuery('broadcast', fetchBroadcastData, {
        initialData: prefetchData, // Access prefetched data
    });
     if (isError) {
         return (
             <div id="error_container" className="text-center">
                 <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                 <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                     Oops! Something went wrong.
                 </h1>
             </div>
         );
     }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="marquee-container container">
                    {broadcastData && broadcastData.filter(item => item.isActive === true).map((item, index) => (
                        <p key={index} className="marquee-item">
                            {item.notification}
                        </p>
                    ))}
                </div>
                
                <nav id="menubar-toolge" style={{ width: "100%", marginBottom: "10px", marginTop: "20px" }} >
                    <div id="" style={{ width: "100%", fontWeight: "600", backgroundColor: "rgb(225,6,0)", color: "white", padding: "15px",
                     border: "none", cursor: "pointer", display: "flex", justifyContent: "space-between",
                      alignItems: "center" }} type="button"
                       
                            className="  d-flex justify-content-between d-block d-lg-none" // Visible on sm and md devices, hidden on lg and larger devices
                         
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBothOptions"
                            aria-controls="offcanvasWithBothOptions"
                            // onClick={handleTogglecanvas}
                        >
                           {""}{""} Menu 
                       
                        <span id="downArrow" style={{ marginLeft: "5px" }}>&#9660;</span>
                    </div>
                </nav>
            </div>
        
          
        </Fragment>
    );
};



