import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ItemProvider } from './Mainpagecomp/Event section/context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { fetchNewsCanvasData } from './Mainpagecomp/Herosection/hero';
import { fetchPublicationData } from './Pages/publicationmenu/publicationapi';
// import Organsapi from './Pages/organs/organsapi';
import { fetchGalleryData } from './Pages/Gallerymenu/galleryapi';
import { organsApi } from './Pages/organs/proletarian';
import { fetchBroadcastData } from './Nav bar/sidenavbar/nav';

// import Dotenv from 'dotenv-webpack';
// import DotenvWebpackPlugin from 'dotenv-webpack';
const queryClient = new QueryClient();
queryClient.prefetchQuery('newsCanvas', fetchNewsCanvasData);
queryClient.prefetchQuery('sarbaharaDristikon', organsApi);
queryClient.prefetchQuery('publicationData', fetchPublicationData);

queryClient.prefetchQuery('broadcast', fetchBroadcastData);
queryClient.prefetchQuery('galleryData', fetchGalleryData);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ItemProvider>
        <App />
      </ItemProvider>
      {/* Include ReactQueryDevtools for development environment */}
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// const dotenvPlugin = new Dotenv();
// dotenvPlugin.apply();
