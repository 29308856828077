import React from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";

import "./org.css"
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import { Nav } from "../../Nav bar/sidenavbar/nav";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";

export const Org=(props)=>{
    // this component created and  re-used in all organisation pages using props method
    // when  make it dynamic it will little different
    return(
        <Fragment>
            <div id="whole_org_container" className="container-fluid">
            
                        <h1 className='Bookssectionmainhead1'>    {props.OrgName}</h1>

                    <div className="org-zic-zaccontainer">
                        <br />
                        <div id="" className="container">
                        <div className="row mb-3">
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                                <div className="org-img-container mb-3">
                                    <h5>{props.date}</h5>
                                    <div className="img_bg">
                                        <img id="_img" src={props.src} alt="Item" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex flex-column  mt-3">
                                <div className="org-details-container">
                                    <h1 className="fs-4 fs-md-1 mt-5 mt-md-0">{props.Title}</h1>
                                    <p>{props.OrgDetails}</p>
                                    <p>{props.OrgDetails1}</p>
                                    <p>{props.OrgDetails2}</p>
                                </div>
                            </div>
                        </div>




                        </div>



                    </div>
                    <br/>
                    
                
         

          
            </div>  </Fragment>
    )  
}

export const  AIDSO=()=>{
    return(
        <Fragment>
         
            <div className="container-fluid">
                <NavBar />

             
             <Leaders />


                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">
            <div>
{/* importing Org component and using props method  */}
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/AIDSO_students.jpg'
                                OrgDetails="     As a mark of rapid pace of progress of AIDSO, a very successful convention of the students from all North-Eastern states i.e., Assam, Meghalaya, Sikkim, Mizoram, Manipur, Arunachal Pradesh, Nagaland and Tripura, was held in Guwahati on 9 December 2022.
   "
                                OrgDetails1="The convention was attended, besides national as well as leaders of the participating states, leaders of AIDSO, by eminent personalities like Dr Ghanashyam Nath, former principal, Guwahati Commerce College, Shri Wandel Passah, educationist from Meghalaya and Prof. Chandralekha Das. There was an audio message of Dr Tapodhir Bhattacharjee, former Vice Chancellor, Assam University.     "
                                OrgDetails2="All the students took pledge to develop countrywide massive students’ movement against disastrous National Education Policy and in demand for secular, scientific and democratic education. The participants were regaled by a beautiful presentation of folk dance and song of North East.
"
                                date="Published: December 19, 20225:23 pm
"
                                OrgName="AIDSO"
                                Title="AIDSO holds successful students’ convention of North Eastern States"
                            />
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/Andhra_pradesh.jpg'
                                OrgDetails="7th Andhra Pradesh State Conference of AIDSO was held on 17 and 18 December 2022, at Tirupati centred on the theme of opposing disastrous National Education Policy 2020. 250 delegates attended the conference. Those who addressed were Dr. Murali Karnam, Professor, Nalsar Law University, Comrades V N R Sekhar and Sourav Ghosh, President and General Secretary, respectively of AIDSO, Comrades B S Amarnath, AP State Secretary, SUCI(C), S. Govind Rajulu, A.P. State Secretary, All India Save Education Committee and Comrades R. Gangadhar, Ajay Kamath and A Satyanaryayana, all AIDSO leaders. A 40 member state committee with Comrades V Harish Kumar Reddy and E Mahesh as President and Secretary was formed.
   "
                                date="Published: January 2, 20236:09 pm"
//                                 OrgDetails1="The convention was attended, besides national as well as leaders of the participating states, leaders of AIDSO, by eminent personalities like Dr Ghanashyam Nath, former principal, Guwahati Commerce College, Shri Wandel Passah, educationist from Meghalaya and Prof. Chandralekha Das. There was an audio message of Dr Tapodhir Bhattacharjee, former Vice Chancellor, Assam University.     "
//                                 OrgDetails2="All the students took pledge to develop countrywide massive students’ movement against disastrous National Education Policy and in demand for secular, scientific and democratic education. The participants were regaled by a beautiful presentation of folk dance and song of North East.
// "

                                
                                Title="AIDSO Andhra Pradesh State Conference resolved to resist NEP-2020
"
                            />
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='./org/West_Bengal.jpg'
                                OrgDetails="West Bengal State Conference of AIDSO was held in Asansol on 17-18 February last. Around 1500 delegates from schools, colleges and medical colleges of all districts participated. On 17 February, a seminar was organized in favour of the demand for annulment of National Education Policy (NEP-20). Thereafter, a well-decorated rally covered the entire city. From the conference, a 31-member state secretariat, 89-member state committee and a 112-member strong state council were elected.
   "
                                OrgDetails1="Comrades Manishankar Pattanayak and Biswajit Roy were elected President and Secretary respectively. Present in the conference were Comrades Sourav Ghosh, General Secretary, and Sachin Jain, Vice President, AIDSO. Comrade Chandidas Bhattacharjee, Polit Bureau member and West Bengal State Secretary, SUCI(C) was the chief guest.
    "
                                date="Published: March 3, 20235:26 pm"
                                OrgDetails2="Comrade Provash Ghosh conducting school of politics after the conference
On 18 February, a school of politics was held. All the delegates attended the school. Comrade Provash Ghosh, General Secretary, SUCI(C), conducted the school. The school was organized with a view to obtaining correct guideline towards building up true revolutionary character based on Marxism-Leninism-Shibdas Ghosh Thought and developing mighty students’ movement against all out cultural degeneration, disastrous NEP and limitless corruption.
  "


                                Title="12th AIDSO West Bengal State Conference held amidst great enthusiasm"
                            />

                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </Fragment>
    )
}