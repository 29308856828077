import React, { useEffect } from "react";
import { Fragment } from "react";



import Slider from "react-slick";
import { Ecard } from "./selectedwork_e";

import { format } from "date-fns";


import { FaExclamationCircle } from "react-icons/fa";


export const ARTICLES_B = ({ Edata, isLoading, isError }) => {

   
    






    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1068,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };

    // const { data: Edata, isLoading, isError } = usePublicationData();

    useEffect(() => {
        // Additional logic if needed
    }, []);

    return (
        <Fragment>
            <div id="Event" className="container-fluid">
                <div className="container-fluid">
                    <div id="statement_container">
                        <h1 id="statement_text">Articles (Bengali)</h1>
                        <span className="d-flex"> <hr id="" /> <span className="dots1"> .... </span> </span>
                    </div>
                    <br />
                    <div className="sliderparent ">
                        {isLoading && <p>Loading...</p>}
                        {isError && <div id="error_container" className="text-center">
                            <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                            <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                                Oops! Something went wrong.
                            </h1>
                        </div>}
                        {Edata && (
                            <Slider {...settings}>
                                {Edata
                                    .filter((item) => item.writer === "Shibdas Ghosh" && item.category === 'Article' && item.lang === "Bengali")
                                    .map((item, index) => (
                                        <div id="cards" key={index}>
                                            <Ecard src={item.image} alt={item.title} title={item.title} date={format(new Date(item.date), "MMM d, YYY")} route={item.doc} />
                                        </div>
                                    ))}
                            </Slider>
                        )}
                    </div>
                </div>
                <br />
            </div>
        </Fragment>
    );
};

