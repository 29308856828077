import React from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";
import { Footer } from "../../Footer/Footer";
import { Org } from "./aidso";
import './org.css';
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import { Nav } from "../../Nav bar/sidenavbar/nav";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";
export const  AIUTUC=()=>{
    return(
        <Fragment>
          
            <div className="container-fluid">
                <NavBar />

               
                <Leaders />


                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">
             <div>
                            {/* importing Org container and using props method  */}
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/AIUTUC_Calls.jpg'
                                OrgDetails="In a statement issued on 7 December 2021, Comrade Shankar Dasgupta, General Secretary, AIUTUC announced whole-hearted support to the call given by the Central Trade Unions to observe countrywide General Strike on 23 and 24 February 2022 in protest against the anti-worker, anti-people and pro-monopoly draconian policies, enactments and sweeping measures to implement them.
 "
                                date="Published: December 17, 20211:48 pm
"
                                OrgDetails1="While calling upon the countrymen to make the strike call a total success, AIUTUC, a constituent of the Joint Platform of the Central Trade Unions, reiterates that only protracted, united powerful movements of the common people in general and working people in particular can bend down the arrogant fascist government and resist its mounting onslaughts on the working people.
     "
//                                 OrgDetails2="All the students took pledge to develop countrywide massive students’ movement against disastrous National Education Policy and in demand for secular, scientific and democratic education. The participants were regaled by a beautiful presentation of folk dance and song of North East.
// "

                                OrgName="AIUTUC"
                                Title="AIUTUC Calls for making countrywide two days’ General Strike a total success"
                            />
                            <Org
                                // src='https://mts-random.s3.ap-south-1.amazonaws.com/suci/org/image1.jpg'
                                src='https://suci-public.s3.ap-south-1.amazonaws.com/image/org/AIUTUC_calls_upon.jpg'
                                OrgDetails="Comrade Sankar Saha, President, AIUTUC, has issued the following statement on 10February 2021 expressed deep concern over the move of the Central Government to privatize two nationalized banks and one Insurance Company. He further said that the banks are becoming sick because of growing NPAs i.e. loans defaulted mostly by large corporate houses. The government which declined to reveal the names of the big defaulters are now handing over the banks to the same corporates so that could take control of huge aggregate amount of Bank deposits which is nothing but hard-earned savings of the common toiling people. As usual, the customers as well as the employees would be the worst victim of this pro-capitalist move.
Comrade Saha urged the employees and people at large to be united to make success the STRIKE called by All India Bank Employees Unity Forum and other banking unions on 15 and 16 March 2021 and be prepared for a prolonged struggle to thwart the pernicious move.

  "
                                date="Published: February 25, 20212:13 pm
"
                                //                                 OrgDetails1="The convention was attended, besides national as well as leaders of the participating states, leaders of AIDSO, by eminent personalities like Dr Ghanashyam Nath, former principal, Guwahati Commerce College, Shri Wandel Passah, educationist from Meghalaya and Prof. Chandralekha Das. There was an audio message of Dr Tapodhir Bhattacharjee, former Vice Chancellor, Assam University.     "
                                //                                 OrgDetails2="All the students took pledge to develop countrywide massive students’ movement against disastrous National Education Policy and in demand for secular, scientific and democratic education. The participants were regaled by a beautiful presentation of folk dance and song of North East.
                                // "


                                Title="AIUTUC calls upon all to support all India bank strike against pernicious move to privatize nationalized banks

"
                            />

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}