import React from "react";
import { Fragment } from "react";

import Card from 'react-bootstrap/Card';


import { useState } from 'react';

import './Contact.css';
import { NavBar } from "../../Nav bar/Navbar";
import { Footer } from "../../Footer/Footer";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";

import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Nav } from "../../Nav bar/sidenavbar/nav";

import axios from "axios";
import getAwsSignature from "../../aws";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const Contact=()=>{

    const [userdata, setuserdata] = useState({
      name: "",
      email: "",
      message: ""
    });

    const handlechange = (event) => {
      const target = event.target;
      const name = target.name;
      const value = target.value;

    

      setuserdata({ ...userdata, [name]: value });
    }

  const validateFields = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!userdata.name) {
      toast.error('Please enter your name.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // alert('Please enter your name.');
      return false;
    }

    if (!userdata.email) {
      toast.error('Please enter your email address.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else if (!emailRegex.test(userdata.email)) {
      toast.error('Please enter a valid email address.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    if (!userdata.message) {
      toast.error('Please enter your message.  ', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
     
      return false;
    }

    return true;
  };


  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    // Include the current date and time in the user data
    const currentDate = new Date().toLocaleString();
    const userDataWithDate = {
      ...userdata,
      date: currentDate
    };

    console.log(userDataWithDate, "User Data");

    try {
      const apiUrl = process.env.REACT_APP_API_URL + '/createquery';

      
      const method = 'POST';

      const headers = await getAwsSignature(apiUrl, method);

      const res = await fetch(apiUrl, {
        method: method,
        headers,
        body: JSON.stringify(userDataWithDate),
      });

      const data = await res.json();
      console.log(data, "userdata");

      // Perform actions after successful data submission

    } catch (error) {
      console.error('Error submitting data:', error);
      // Handle error appropriately
    }

    var data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_USER_ID,

    
      template_params: {
        from_email: userdata.email,
        from_name: userdata.name,
        message: userdata.message
      }
    };

    try {
      // Assume axios is imported correctly
      const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
      console.log(res.data);
      

      toast.success('Message sent successfully', {
        position: "top-right",
        autoClose: 3000, // Close the toaster after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Correct usage
      setuserdata((prevUserData) => ({
        ...prevUserData,
        name: "",
        email: "",
        message: ""
      }));
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };



    return(
        <Fragment>
        <NavBar />
   
        <Leaders />
       
        <div id="homepage_container" className="row">
          <Nav/>
          <div className=" col-xs-1 col-sm-12   col-md-12 col-lg-2   col-xl-2  col-xxl-2 ">
            <Sidebar />
          </div>
          <div id="main_component" className="col-sm-12  col-md-12 col-lg-10   col-xl-10  col-xxl-10 ">

          <div>
              <div id="statement_container">
                <h1 id="statement_text">Contact us
                </h1>
                <span className="d-flex">  <hr id="statementhr" /> <span className="dots1"> .... </span> </span>   </div>


              <div className="contactus_card_container">


                <Card id="contact_card" >

                  <div id="contact_title_con" className="d-flex"  >
                    <img alt="contact logo" id="logo_contact" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/logo/logo1.jpg" />
                    <Card.Title id="contact_title">Contact us</Card.Title>

                  </div>
                  <Card.Body id="contact_card_body">
                    <div class="mb-3">
                      <label for="name" class="form-label">Name</label>
                      <input onChange={handlechange} type="text" name="name" value={userdata.name} class="form-control" id="name" placeholder="your name" />
                    </div>
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input onChange={handlechange} value={userdata.email} type="email" name="email" class="form-control" required id="email" placeholder="name@example.com" />
                    </div>
                    <div class="mb-3">
                      {/* <label for="message" class="form-label">Example textarea</label> */}
                      <textarea onChange={handlechange} value={userdata.message} class="form-control" name="message" placeholder="message" id="message" rows="3"></textarea>
                    </div>
                    <div class="mb-3">
                   
  <input type="submit" onClick={handleSubmit} class="form-control" value="send " id="submit" placeholder="name@example.com" />
                  
                    </div>

                  </Card.Body>
                </Card>

            
              </div>
              <div className="container-fluid" >
                <div id="statement_container">
                  <h1 id="statement_text">Location
                  </h1>
                  <span className="d-flex">  <hr id="statementhr" /> <span className="dots1"> .... </span> </span>   </div>

<br />
              
                <div className="contact_info_container">

                  <div >
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3684.430676333293!2d88.35567757454918!3d22.562990079498576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20SUCI%20(Communist)%20Central%20Committee%20%2C%2048%20Lenin%20Sarani%2C%20Kolkata-700013%20%20%20%20%20%20%20%20%20%20%20Phone%3A%20%2B9133-2249-1828%2C!5e0!3m2!1sen!2sin!4v1697010529008!5m2!1sen!2sin"
                      width="100%" height="350"
                      allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>

                </div>
              </div>
          </div>
         
            



        
          </div>  
          </div>

        <ToastContainer />
          
            <Footer/>
        </Fragment>
    )
}