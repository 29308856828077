// usePublicationData.js
import { useQuery, useQueryClient } from 'react-query';

 export const fetchPublicationData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/publication';
    const response = await fetch(apiUrl);

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
// console.log('publication', data);
    return data.map((item) => ({
        ...item,
        date: new Date(item.date).toLocaleDateString('en-US'),
    }));
};
 const usePublicationData = () => {
    const queryClient = useQueryClient();

    // Attempt to get the prefetched data
    const prefetchedData = queryClient.getQueryData('publicationData');

    // Use the useQuery hook with the prefetched data
    const { data: fetchedData, isLoading, isError, refetch } = useQuery('publicationData', fetchPublicationData, {
        initialData: prefetchedData, // Use the prefetched data as initial data
        refetchInterval: 60000, // Refetch every 60 seconds (adjust as needed)
        cacheTime: 60000,
    });

    return { data: fetchedData, isLoading, isError, refetch };
};
export default usePublicationData;