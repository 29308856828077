import React from "react";
import { NavBar } from '../../../Nav bar/Navbar';

import './founder.css';
import { Fragment } from "react";

import { Footer } from "../../../Footer/Footer";
import { Leaders } from "../../../Mainpagecomp/Leader/leaders";

import { Sidebar } from "../../../Nav bar/sidenavbar/sidebar";

import { Nav } from "../../../Nav bar/sidenavbar/nav";
import { Link } from "react-router-dom";

export const Founder = () => {
    return (
        <Fragment>

            <div className="container-fluid">
                <NavBar />

              
             <Leaders />
              

                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-2   col-md-2 col-lg-2   col-xl-2  col-xxl-2">
                        <Sidebar />
                    </div>
                    <div id="main_component" className=" col-xs-1 col-sm-2   col-md-12 col-lg-10  col-xl-10  col-xxl-10">
                      
                        <div className="foundercontainer">

                            <div className="row">

                                <div className="  col-sm-12   col-md-6 col-lg-6   col-xl-6  col-xxl-6 ">
                                    <div>
                                        <img alt="founder" id="founderimg" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/homage/bc100.jpg" />
                                    </div>
                                </div>
                                <div  className="col-sm-12  col-md-6 col-lg-6  col-xl-6  col-xxl-6 ">
                                    <div className="founderdetailcontainer">
                                        <h1>
                                            Com. Shibdas Ghosh
                                        </h1>
                                        <p>
                                            COMRADE SHIBDAS GHOSH, the leader, teacher, guide and General Secretary of our Party, the Socialist Unity Centre of India, breathed his last following an acute myocardial infarction (severe and massive heart attack) at 7-30 p.m. on Thursday, the 5th August, 1976, at the party’s Central Commune in Calcutta at the age of 53. Immediately after his sudden and premature demise the Central Committee took the following resolution paying homage to the memory of the great departed leader.
                                        </p>
                                        <p>
                                            The Central Committee of our party expresses its profound and deepest grief at the sudden and premature demise of COMRADE SHIBDAS GHOSH our beloved leader, teacher, guide and General Secretary of our Party, the Socialist Unity Centre of India. He was one of the outstanding Marxist thinkers of the present era. It was COMRADE SHIBDAS GHOSH who concretised Marxism-Leninism on the Indian soil and developed, elaborated and enriched many aspects of it and also brought many other aspects to a new height at this particular stage of the present era of Imperialism and Proletarian Revolution enunciated by Lenin. He also provided a newer and concrete understanding of Marxism-Leninism in the course of not only dealing with the problems that appeared in the political and socio-economic fields, but also covering all aspects of life and epistemology  science and philosophy, ethics, morality, culture, art, literature and aesthetics in the post-Lenin period. So, it is incumbent upon all to make a serious cultivation of the thoughts of COMRADE SHIBDAS GHOSH without which the understanding of Marxism-Leninism at this stage of the present era cannot be adequate.

                                        </p>

                                        <p>
                                            Realizing the need of Indian revolution, marked by absence of a genuine working class party, COMRADE SHIBDAS GHOSH took upon himself the historic task of building a revolutionary party of the proletariat with which the question of emancipation of the people from all sorts of exploitation and oppression was inseparably linked up. In the process of building the party COMRADE SHIBDAS GHOSH historically emerged as the great leader of the toiling millions. In this struggle for building a genuine revolutionary party of the proletariat in our country, COMRADE SHIBDAS GHOSH elevated to a new height the Leninist concept of Marxist party and pointed out that only after achieving ideological centralism by conducting an intense ideological struggle covering all aspects of life organizational centralism can be achieved and only then the party can develop as an organic whole. In him was personified and concretized the collective leadership of our party.

                                        </p>
                                        <p>
                                            His contribution to the ideological fields of international communist movement made him a communist leader of unique distinction. He was the first to point out that modern revisionism-reformism was the main danger in the presentday international communist movement and showed the correct line of struggle against it. His contribution toward the fight against all sorts of illusion which are being created by different schools of bourgeois philosophy and its modern trends will act as an indispensable and valuable guide not only to the revolutionary movement of our country but to the international communist movement as well.

                                        </p>
                                        <p>
                                            His ceaseless fight against economism, opportunism and diverse varieties of social democratism that have permeated through and through the working class movement gave a correct and new direction to the working class struggle of our country. His clarion call to the toiling millions of our country was that they should always adhere to the correct base political line of the proletariat and build the political power of the people without which the cherished goal of their emancipation can never be achieved.

                                        </p>

                                        <p>
                                            He consistently upheld the nobility of communist ideology and taught us that the living soul of any great ideal is in its higher standard of morals, ethics and culture. He taught us to practise and develop the working class revolutionary politics on the basis of communist ethics and morality. It was also his teaching not to be afraid of any difficulties but to face all adversities with revolutionary zeal and determination and be able thus to turn disadvantage into advantage.

                                        </p>
                                        <p>
                                            The Central Committee firmly believes that the correct and scientific analysis made by him of each and every national and international question shall surely act as an invaluable guide to the proletariat in the struggle for emancipation for decades to come. The Central Committee is also of the firm opinion that many of his contributions in the field of epistemology are definite new additions to the treasury of Marxism-Leninism.

                                        </p>
                                        <p>
                                            The Central Committee is convinced that following the great example of his unique revolutionary character in which was completely identified his self with the class, party and revolution all leaders, workers, supporters and sympathizers will dedicate themselves more and more to the cause of revolution and will increasingly identify themselves with the party founded by our great leader, teacher and guide COMRADE SHIBDAS GHOSH. At this hour of great loss we must turn our grief, as he taught us, into firm determination, courage and revolutionary purposiveness. We take the vow to stand as ‘one man’ and strive hard to fill in the void created. He is and shall ever remain very much with us through his teachings. His name and teachings will endure as the living source of inspiration and the beacon light to the legions of revolutionaries who will fulfil the behest of COMRADE SHIBDAS GHOSH. Let all of us, thousands of disciples and admirers of COMRADE GHOSH, hold aloft the banner of revolution and proletarian internationalism with every drop of our blood.

                                        </p>
                                    </div>
                                </div>    </div>

                                  
                                </div>
                        <div id="card-share" className="d-flex justify-content-center">
                            <Link to="/">


                                <svg fill="#e10600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 495.398 495.398" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"></path> <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"></path> </g> </g> </g> </g></svg>

                            </Link>



                        </div>   </div>
                        </div>


                        <Footer />

                    </div></Fragment>
                )
}