import React, { useEffect } from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";

import { Footer } from "../../Footer/Footer";

import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import { FaExclamationCircle } from "react-icons/fa";
import Slider from "react-slick";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";

import { Nav } from "../../Nav bar/sidenavbar/nav";

import { Ecard } from "./selectedwork_e";
import { format } from "date-fns";
import usePublicationData from "./publicationapi";

export const Nihar = () => {
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1068,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const { data: Edata, isLoading, isError } = usePublicationData();

    useEffect(() => {
        // Additional logic if needed
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <NavBar />
             <Leaders />

                <div id="homepage_container" className="row">
                    <Nav />
                    <div className=" col-xs-1 col-sm-12   col-md-2col-lg-2   col-xl-2  col-xxl-2">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12 col-md-12 col-lg-10  col-xl-10  col-xxl-10">
                        <div id="statement_container">
                            <h1 id="statement_text">Nihar Mukherjee Books </h1>
                            <span className="d-flex">
                                {' '}
                                <hr id="" /> <span className="dots1"> .... </span>{' '}
                            </span>
                        </div>
                        <br />
                        <div className="sliderparent ">
                            {isLoading && <p>Loading...</p>}
                            {isError && <div id="error_container" className="text-center">
                                <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                                <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                                    Oops! Something went wrong.
                                </h1>
                            </div> }
                            {Edata && (
                                <Slider {...sliderSettings}>
                                    {Edata
                                        .filter((item) => item.writer === "Nihar Mukreejee" )
                                        .map((item, index) => (
                                            <div id="cards" key={index}>
                                                <Ecard
                                                    src={item.image}
                                                    alt={item.title}
                                                    title={item.title}
                                                    date={format(new Date(item.date), 'MMM d, YYY')}
                                                    route={item.doc}
                                                />
                                            </div>
                                        ))}
                                </Slider>
                            )}
                        </div>
                        </div>
                    {/* <div id="card-share" className="d-flex justify-content-center">
                        <Link to="/">


                            <svg fill="#e10600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 495.398 495.398" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"></path> <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"></path> </g> </g> </g> </g></svg>

                        </Link>



                    </div>    */}
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
};
