
import React from "react";
import { Fragment } from "react";

import './leaders.css';


/* eslint-disable jsx-a11y/alt-text */

export const Leaders=()=>{
  return(
      <Fragment>
        <div className="container-fluid mt-2">
           <div className=" container d-flex justify-content-center g-3 mx-auto p-2">
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader1.jpg" />
              </div>
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader2.jpg" />
              </div>
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader3.jpg" />
              </div>
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader4.jpg" />
              </div>
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader5.jpg" />
              </div>
              <div className="limg_bg mx-3">
                 <img alt="cc" id="l_img" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/leader/leader6.jpg" />
              </div>
           </div>
        </div>



        
{/* <div className="  d-flex container-fluid"   id="leadercontainer">


  <div id="leaderbox " >
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" 
         src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader1.jpg "/>
        </div>
 
  <div id="leaderbox">
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader2.jpg"  />
        </div>
 
  <div id="leaderbox">
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader3.jpg "  />
        </div>

   <div id="leaderbox">
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader4.jpg"  />
        </div>
 
   
  <div id="leaderbox">
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader5.jpg "  />
        </div>
 
  <div id="leaderbox">
        <div id="leaderphoto" className="container">
           </div>
        <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader6.jpg"  />
        </div>
      
       

        </div> */}
        


            
        </Fragment>
    )
}



// export const Card=()=>{
//   return(
//       <Fragment>
        
//         <div  id="card" className=" carsoulcontainer container-fluid">

  
//     <Carousel id="carsoul" indicators={false} controls={false}>
//       <Carousel.Item>
    
//         <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader6.jpg "/>
//         </div>
      
       
    
//         <Carousel.Caption>
//         <div id="details">
//         <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item>
//       <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader5.jpg"/>
//         </div>
//         <Carousel.Caption>
//         <div id="details">

    
//     <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item>
//       <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader4.jpg "/>
//         </div>
//         <Carousel.Caption>
//         <div id="details">
    
//         <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item>
//       <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader3.jpg"/>
//         </div>
//         <Carousel.Caption>
//         <div id="details">
//         <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item>
//       <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader2.jpg "/>
//         </div>
//         <Carousel.Caption>
//         <div id="details">
    
//         <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item>
//       <div>
//         <div id="leaderphoto" className="container">
//            </div>
//         <img id="leader" src=" https://mts-random.s3.ap-south-1.amazonaws.com/suci/leader/leader1.jpg"/>
//         </div>
//         <Carousel.Caption>
//         <div id="details">
//         <span>''</span>
//     <p>  Contrary to popular belief,
//          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it 
//          over 2000 years old. Richard McClintock, a Latin professor 
//          at Hampden-Sydney College in Virginia </p><span> ,,</span>

   
// {/* <p>
// "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
// </p> */}
//     {/* <h1>
//     Lorem Ipsum...
//     </h1> */}

    
//     </div>
//         </Carousel.Caption>
//       </Carousel.Item>
//     </Carousel>
  



// </div>
            
//         </Fragment>
//     )
// }