// awsSignature.js

// Import necessary AWS SDK and AWS4 libraries
import AWS from 'aws-sdk';
import aws4 from 'aws4';

// /**
//  * Generates AWS Signature for making authenticated API requests.
//  *
//  * @param {string} apiUrl - The URL of the API endpoint.
//  * @param {string} method - The HTTP method for the request (e.g., 'GET', 'POST').
//  * @param {object} payload - The payload data for the request.
//  * @returns {object} - Headers containing the AWS Signature for authentication.
//  */
const getAwsSignature = (apiUrl, method, payload) => {
    // Create AWS Credentials object with access key and secret key
    const credentials = new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    // Get AWS region from environment variables or default to 'ap-south-1'
    const region = process.env.REACT_APP_AWS_REGION 

    // Generate AWS Signature using aws4 library
    const signedRequest = aws4.sign({
        host: new URL(apiUrl).host,
        method: method,
        url: apiUrl,
        path: new URL(apiUrl).pathname,
        headers: {
            'Content-Type': 'application/json',
        },
        // Include the request payload if present
        // body: JSON.stringify(payload),
    }, credentials, { region: region });

    // Return the headers containing the AWS Signature
    return signedRequest.headers;
};

// Export the function as the default export for reuse
export default getAwsSignature;
