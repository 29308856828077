import React from "react";
import { Fragment } from "react";
import './authorities.css';
import Slider from "react-slick";

import Card from 'react-bootstrap/Card';

import { NavBar } from "../../../Nav bar/Navbar";
import { Leaders } from "../../../Mainpagecomp/Leader/leaders";

import { Sidebar } from "../../../Nav bar/sidenavbar/sidebar";
import { Footer } from "../../../Footer/Footer";
import { Nav } from "../../../Nav bar/sidenavbar/nav";
import { Link } from "react-router-dom";
export const Authorities = () => {
  var settings = {
    dots: true,
    infinite: false,
    
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1068,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },

      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };






  return (
    <Fragment>
      {/*  #Central Committee memebers page
           # in this component we are using react slick to create carousel
      */}
  
        <div className="container-fluid">
          <NavBar />

         
         
          <Leaders />
         
          <div id="homepage_container" className="row">
            <Nav />
            <div className=" col-xs-1 col-sm-3   col-md-2 col-lg-2   col-xl-2  col-xxl-2">
              <Sidebar />
            </div>
            <div id="main_component" className="col-sm-12 col-md-12 col-lg-10  col-xl-10  col-xxl-10">
              <div className="central_committee_whole_container">
                <h1 className='Bookssectionmainhead1'>Central Committee</h1>
                <div id="centralcommitte" className="container ">



                  <div className="central_committe_memeber">
                    <h3 className='Bookssectionmainhead1'> 1st Central Committee Members</h3>
                    <br />
                    {/* react slick carousel */}
                    <div className="container-fluid slide_container_c">
                      <Slider {...settings}>

                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/shibdas.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename">Com. Shibdas Ghosh</Card.Title>
                              </div>


                            </Card.Body>
                          </Card>
                          <br />
                        </div>
                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/nihar.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename"    >Com. Nihar Mukherjee
                                </Card.Title>
                              </div>

                                </Card.Body>
                          </Card>
                        </div>
                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/sachin.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename">Com. Sachin Banerjee
                                </Card.Title>
                              </div>

                            

                            </Card.Body>
                          </Card>
                        </div>
                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/subodh.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename">Com. Subodh Banerjee
                                </Card.Title>
                              </div>

                            
                            </Card.Body>
                          </Card>
                          <br />
                        </div>

                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/hiren.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename"  >Com. Hiren Sarkar
                                </Card.Title>
                              </div>



                            </Card.Body>
                          </Card>

                        </div>

                        <div id="eachcard" >

                          <Card id="Profilecard">
                          <Card.Img variant="top" id="ProfileImage" src="https://suci-public.s3.ap-south-1.amazonaws.com/image/cc/pritish.jpg" />
                            <Card.Body>
                              <div id="footer" className="card-footer">
                                <Card.Title id="profilename"  >Com. Pritish Chanda
                                </Card.Title>
                              </div>

                             
                            </Card.Body>
                          </Card>
                        </div>




                      </Slider>
                    </div>

                  </div>
                  <br />
                  <div className="central_committe_memeber">
                    <h3 className='Bookssectionmainhead1'> Current Central Committee Members</h3>
                    <br />
                    <div className="member_list">
                      <ol>
                        <li>
                          Com. Provash Ghosh, <strong> General Secretary</strong>
                        </li>
                        <li>
                          Com. Asit Bhattacharyya <strong> (Politburo Member)</strong>
                        </li>


                        <li>Com. Saumen Basu  <strong>(Politburo Member)  </strong> </li>
                        <li>Com. Amitava Chatterjee <strong>(Politburo Member)  </strong>  </li>
                        <li>Com. Chandidas Bhattacherjee  <strong>(Politburo Member)</strong>   </li>
                        <li>Com. Gopal Kundu <strong>(Politburo Member) </strong> </li>
                        <li>Com. K Radhakrishna <strong>(Politburo Member) </strong>  </li>
                        <li> Com. Kantimoy Deb  <strong>(Politburo Member) </strong> </li>
                        <li>Com. Rabin Samajpati  <strong>(Politburo Member)</strong> </li>
                        <li>  Com. Satyawan  <strong>(Politburo Member)</strong>  </li>



                        <li>  Com. Swapan Ghosh <strong> (Politburo Member)</strong>  </li>
                        <li>Com. V Venugopal <strong>(Politburo Member)</strong>  </li>
                        <li>Com. Arun Singh</li>
                        <li>Com. A Rengasamy</li>
                        <li>Com. Arun Kumar Bhaumik</li>
                        <li>Com. Asoke Samanta</li>
                        <li>Com. BS Amarnath</li>
                        <li>Com. Chandralekha Das</li>
                        <li>Com. Chhaya Mukherjee</li>
                        <li> Com. Chiraranjan Chakraborty</li>

                        <li>Com. Debasish Roy</li>
                        <li>Com. Dwarika Rath</li>
                        <li>Com. Jaison Joseph</li>
                        <li>Com. K Sridhar</li>
                        <li>Com. K Uma</li>
                        <li>Com. Manab Bera</li>
                        <li>Com. Pratap Samal</li>
                        <li>Com. Sankar Dasgupta</li>
                        <li>Com. Sankar Ghosh</li>
                        <li>Com. Subhash Dasgupta</li>
                        <li>Com. Surat Zaman Mandal</li>
                        <li>Com. Swapan Chatterjee</li>
                        <li>Com. Swapan Ghosal</li>



                      </ol>
                    </div>
                  </div>












                </div>
              </div>

           
            <div id="card-share" className="d-flex justify-content-center">
              <Link to="/">


                <svg fill="#e10600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 495.398 495.398" xmlSpace="preserve" stroke="#e10600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"></path> <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"></path> </g> </g> </g> </g></svg>

              </Link>



            </div>    </div>
        
          </div>
        <Footer />
        </div> </Fragment>
   
  )
}

