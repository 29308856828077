import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { NavBar } from "../../Nav bar/Navbar";
import { Footer } from "../../Footer/Footer";
import { Leaders } from "../../Mainpagecomp/Leader/leaders";
import { Sidebar } from "../../Nav bar/sidenavbar/sidebar";
import "./proleterian.css";
import { Nav } from "../../Nav bar/sidenavbar/nav";
import getAwsSignature from "../../aws";
import Loader from "../../loader";
import { FaExclamationCircle } from 'react-icons/fa';
import { useQuery, useQueryClient } from 'react-query';

// API function to fetch organs data
 export const organsApi = async () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/organs';
    const method = 'GET';

    // Fetch AWS signature for secure API request
    const headers = await getAwsSignature(apiUrl, method);

    // API request
    const response = await fetch(apiUrl, {
        method,
        headers,
    });

    // Parse JSON response
    const data = await response.json();
    console.log(data, "proletar");

    return data;
};

// Custom hook to fetch and manage organs data
export const useOrgansData = () => {
    const queryClient = useQueryClient();

    const prefetchedData = queryClient.getQueryData('sarbaharaDristikon');

    // Use the useQuery hook with the prefetched data
    const { data: fetchedData, isLoading } = useQuery('sarbaharaDristikon', organsApi, {
        initialData: prefetchedData,
    });

    return { data: fetchedData, isLoading };
};

export const Proletarian = React.memo(() => {
    const queryClient = useQueryClient();
    const prefetchedData = queryClient.getQueryData('sarbaharaDristikon');

    // Use the useQuery hook with the prefetched data
    const { data: rawData, isLoading, isError } = useQuery('sarbaharaDristikon', organsApi, {
        initialData: prefetchedData,
        onSuccess: (data) => {
            queryClient.invalidateQueries('otherQueryKey');
        },
    });

    // State variables to store formatted data, years, and selected year
    const [formattedData, setFormattedData] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    // Handle change of selected year
    const handleYearChange = useCallback(
        (e) => {
            setSelectedYear(parseInt(e.target.value));
        },
        [setSelectedYear]
    );

    useEffect(() => {
        if (isLoading || !rawData || rawData.length === 0) {
            return;
        }

        // Format data and update state
        const formattedData = rawData
            .filter((item) => item.name === 'proletarian era')
            .map((item) => ({
                ...item,
                date: new Date(item.pub_date).toLocaleDateString('en-GB'),
            }));

        const uniqueYears = Array.from(new Set(formattedData.map((item) => new Date(item.pub_date).getFullYear())));
        const sortedYears = uniqueYears.sort((a, b) => b - a);

        const defaultSelectedYear = Math.max(...sortedYears);

        setSelectedYear(defaultSelectedYear);
        setYears(sortedYears);
        setFormattedData(formattedData);
    }, [isLoading, rawData, queryClient]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return (
            <div id="error_container" className="text-center">
                <FaExclamationCircle className="error-icon" style={{ color: 'rgb(225,6,0)', fontSize: '20px' }} />
                <h1 id="error_text" style={{ color: 'rgb(225,6,0)', fontSize: '16px', fontFamily: 'barlow condensed' }}>
                    Oops! Something went wrong.
                </h1>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <NavBar />
                <Leaders />
                <div id="homepage_container" className="row">
                    <Nav />
                    <div className="col-xs-1 col-sm-2 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                        <Sidebar />
                    </div>
                    <div id="main_component" className="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                        <div id="statement_container">
                            <h1 id="statement_text">Proletarian Era</h1>
                            <span className="d-flex">
                                {' '}
                                <hr id="statementhr" />
                                <span className="dots1"> .... </span>{' '}
                            </span>{' '}
                        </div>
                        <br />
                        <div style={{ marginLeft: '100px !important', width: '99%' }} className="card">
                            <nav className="d-flex justify-content-between align-items-center" style={{ background: 'white', padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <h2 style={{ color: 'rgb(225, 6, 0)', fontWeight: 'bold', margin: '0' }}>
                                    {selectedYear}
                                </h2>
                                <select style={{ width: '200px', margin: '0', fontWeight: 'bold', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }} className="form-select" aria-label="Default select example" value={selectedYear} onChange={handleYearChange}>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </nav>
                            <div id="months_container" className="card-body d-flex align-items-center">
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: '20px' }}>
                                    {formattedData
                                        .filter((item) => new Date(item.pub_date).getFullYear() === selectedYear)
                                        .map((item, index) => (
                                            <div
                                                key={index}
                                                className="responsive-card"
                                                style={{
                                                    borderRadius: '8px',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                    marginBottom: '20px',
                                                    width: '200px',
                                                    height: '100%',
                                                    position: 'relative',
                                                }}
                                            >
                                                <a href={item.doc}>
                                                    <img
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: '8px 8px 0 0',
                                                        }}
                                                        src={item.thumbnail}
                                                        alt={`Publication Thumbnail ${index}`}
                                                    />
                                                </a>
                                                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '5px', backgroundColor: 'rgba(255, 255, 255, 0.8)', textAlign: 'center' }}>
                                                    <h6 style={{ fontSize: '12px', fontWeight: 'bold', margin: '0' }}>
                                                        {item.date}
                                                    </h6>
                                                    <h5 style={{ fontSize: '16px', fontWeight: 'bold', margin: '3px 0' }}>
                                                        Volume {item.volume} No. {item.no}
                                                    </h5>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
});




